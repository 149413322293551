import React from 'react'
import './CourseListItem.scss'
import CheckedIcon from '../../assests/icons/green-checked.png'
import { ApiLesson } from '../../../interfaces/MemberPortal'
import { FetchApiData } from '../../../services/useFetchData'
import useGlobalState from '../../../context/useGlobalState'
import { useLocation } from 'react-router-dom'

interface ListItemProps {
    item: ApiLesson
}

const CourseListItem: React.FC<ListItemProps> = ({ item }) => {
    const { fetchLesson } = FetchApiData();
    const { exerciseId } = useGlobalState();
    const location = useLocation();

    const handleMenuItem = (id: number) => {
        if (location.pathname.startsWith('/section')) return
        fetchLesson(id)
    }

    return (
        <div className={`course-list-component ${exerciseId == item.id && "active-module"}`} onClick={() => handleMenuItem(item.id)}>
            {
                item.is_completed ?
                    <img src={CheckedIcon} alt='icon' className='checked-icon-green' />
                    :
                    <img src={item.icon} alt='icon' className='checked-icon-green' />
            }
            <div className='course-details'>
                <span className='course-item-title'>
                    {item.title}
                </span>
            </div>
        </div>
    )
}

export default CourseListItem