import { Routes, Route } from "react-router-dom";
import { userType } from "../utils/constants";
import AuthenticatedRoute from "./AuthenticatedRoute";
import VerifyMagicLink from "../component/pages/login/MagicTokenVerification";
import AgeGate from "../component/pages/age-gate/index";
import AccountInfo from "../component/pages/account-info/index";
import Questions from "../component/pages/yes-no-questions";
import SupportRequest from "../component/pages/reason-for-coaching-support/index";
import UserInformation from "../component/pages/user-information/index";
import MeetCoach from "../component/pages/meet-coach/index";
import GeneralSelfSchedulingQuestions from "../component/pages/self-schedule/index";
import CallPage from "../component/pages/call-page/index";
import ChatPage from "../component/pages/chat/index";
import InvalidLink from "../component/pages/invalid-link/index";
import RadioButtonAssessmentPage1 from "../component/pages/self-schedule/RadioButtonAssessmentPage1";
import RadioButtonAssessmentPage2 from "../component/pages/self-schedule/RadioButtonAssessmentPage2";
import WorkLifeCategories from "../component/pages/work-life/worklife-categories/index";
import Test from "../component/pages/test/index";
import Error from "../component/pages/error/index";
import PageNotFound from "../component/pages/page-not-found/index";
import PrivacyPolicy from "../component/pages/privacy-policy/index";
import ServicesCheck from "../component/pages/service-check/index";
import TermsOfService from "../component/pages/terms-of-service";
import RequestOptions from "../component/pages/work-life/request-options/index";
import RequestForm from "../component/pages/work-life/request-form/index";
import SuccessMessage from "../component/pages/work-life/success-message/index";
import ProviderNetworkData from "../component/pages/provider-network-data/index";
import ProviderNetwork from "../component/pages/provider-networks/index";
import SendRefferal from "../component/pages/send-refferal/index";
import DashBoard from "../component/pages/dashboard/index";
import EAPCaseFile from "../component/pages/prt-dashboard/index";
import WorkLifeForm from "../component/pages/work-life/work-life-form/index";
import PRTManagerDashboard from "../component/pages/prt-manager/index";
import Settings from "../component/pages/prt-manager-settings/index";

import SplashScreen from "../views/splash-screen/index";
import SignUp from "../views/sign-up/index";
import Login from "../views/login/index";
import MemberPortalHome from "../views/home/index";
import ExploreTopics from "../views/expore-topics/index";
import MentalHealth from "../views/module/index";
import MentalHealthCourse from "../views/module-section/index";
import IndividualSupport from "../views/individual-support/index";
import FAQs from "../views/faq/index";
import ContactUs from "../views/contact-us/index";
import Excercise from "../views/exercise/index";
import ContentMedia from "../views/content-media/index";

const AppRouter = () => {
  return (
        <Routes>
          {/* New Routes */}
          <Route path="/" element={<SplashScreen />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} /> 
          <Route path="/home" element={<MemberPortalHome />} /> 

          {/* <Route path="/home" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<MemberPortalHome />} />} /> */}
          <Route
            path="/verify/:user_id/:magic_token"
            element={<VerifyMagicLink />}
          />
          {/* <Route path="/explore-topics" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<ExploreTopics />} />} /> */}
          <Route path="/explore-topics" element={<ExploreTopics />} />
          <Route path="/module/:id" element={<MentalHealth />} />
          <Route path="/section/:id" element={<MentalHealthCourse />} />
          <Route path="/exercise/:id" element={<Excercise />} />
          <Route path="/content-media/:id" element={<ContentMedia />} />
          <Route path="/individual-support" element={<IndividualSupport />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          {/* CUSTOMER ROUTES */}
          <Route path="/age-gate" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<AgeGate />} />} />
          <Route path="/account-info" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<AccountInfo />} />} />
          <Route path="/question-one" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<Questions />} />} />
          <Route path="/question-two" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<Questions />} />} />
          <Route path="/question-three" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<Questions />} />} />
          <Route path="/user-info" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<UserInformation />} />} />
          <Route path="/support-request" element={<SupportRequest />} />
          <Route path="/meet-coach" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<MeetCoach />} />} />
          <Route
            path="/general-questions"
            element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<GeneralSelfSchedulingQuestions />} />}
          />
          <Route
            path="/standardized-survey-one"
            element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<RadioButtonAssessmentPage1 />} />}
          />
          <Route
            path="/standardized-survey-two"
            element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<RadioButtonAssessmentPage2 />} />}
          />
          <Route path="/chat/:ticketId" element={<ChatPage />} />

          {/* WORK-LIFE-REQUEST-FLOW ROUTES  */}
          <Route path="/worklife-categories" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<WorkLifeCategories />} />} />
          <Route path="/worklife-form" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<WorkLifeForm />} />} />
          <Route path="/category/:categoryId" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<RequestOptions />} />} />
          <Route path="/request-form" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<RequestForm />} />} />
          <Route path="/success-message" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<SuccessMessage />} />} />

          {/* PRT_USER ROUTES */}
          <Route path="/dashboard" element={<AuthenticatedRoute allowedType={userType.PRT_USER} children={<DashBoard />} />} />
          <Route
            path="/provider-network-info"
            element={<AuthenticatedRoute allowedType={userType.PRT_USER} children={<ProviderNetworkData />} />}
          />
          <Route path="/provider-networks" element={<AuthenticatedRoute allowedType={userType.PRT_USER} children={<ProviderNetwork />} />} />
          <Route path="/send-refferal" element={<AuthenticatedRoute allowedType={userType.PRT_USER} children={<SendRefferal />} />} />
          <Route path="/prt-dashboard" element={<AuthenticatedRoute allowedType={userType.PRT_USER} children={<EAPCaseFile />} />} />
          <Route path="/prt-manager-dashboard" element={<PRTManagerDashboard />} />
          <Route path="/prt-settings" element={<Settings />} />

          {/* GENERAL ROUTES */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/call-page" element={<CallPage />} />
          <Route path="/invalid-link" element={<InvalidLink />} />
          <Route path="/system-check" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<ServicesCheck />} />} />
          <Route path="/test" element={<Test />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
  );
};

export default AppRouter;
