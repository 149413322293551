import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getCustomers, sendRefferalEmail } from "../../../utils/ApiClient";
import CustomerFormModal from "../../common/Modal/CreateCustomerModal";
import "./SendRefferal.scss";
import useGlobalState from "../../../context/useGlobalState"
import { common } from "../../../utils/constants";

interface Customers {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  user_type: string;
}

interface RowData {
  [key: string]: any;
}

const SendRefferal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { rowData, indexName } = location.state || {};
  const [data, setData] = useState<RowData>({});
  const [customer, setCustomer] = React.useState<Customers | null>(null);
  const [newCustomer, setNewCustomer] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<[]>([]);
  const { globalErrorHandler } = useGlobalState();
  const [open, setOpen] = React.useState(false);
  const divRef: any = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isInitialMount = useRef(true);

  const defaultProps = {
    options: customerList,
    getOptionLabel: (option: Customers) => option.email,
  };

  const getCustomerList = async () => {
    try {
      const response = await getCustomers();
      setCustomerList(response.data);
      const addedCustomer: number = response.data.length - 1;
      if (newCustomer) {
        setCustomer(response.data[addedCustomer])
      }
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const customerFromModal = (addedUser: boolean) => {
    setNewCustomer(addedUser);
  };

  useEffect(() => {
    setData(rowData);
    if (newCustomer) { isInitialMount.current = true }
    if (isInitialMount.current) {
      getCustomerList();
    }
    return () => { isInitialMount.current = false }
  }, [rowData, newCustomer]);

  const handleChange = (key: any, value: any) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSendRefferal = async () => {
    if (!customer) {
      setError(true);
    } else {
      setDisabled(true);
      setError(false);
      try {
        await sendRefferalEmail(data, customer, indexName);
        setDisabled(false);
        navigate("/dashboard");
      } catch (error) {
        globalErrorHandler(error);
        setDisabled(false);
      }
    }
  };

  return (
    <div className="send-refferal-page">
      <div className="recipient-div">
        <span className="search-box-label">To</span>
        <Autocomplete
          {...defaultProps}
          id="controlled-demo"
          value={customer}
          onChange={(event: any, newValue: Customers | null) => {
            setCustomer(newValue);
          }}
          className="search-box"
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Customer"
              variant="standard"
              fullWidth
            />
          )}
        />
        <button className="question-button-yes" onClick={handleOpen}>
          Add Customer
        </button>
      </div>
      {open && (
        <CustomerFormModal
          open={open}
          handleClose={handleClose}
          customerFromModal={customerFromModal}
        />
      )}
      <div className="content-div">
        <div ref={divRef} className="content-area">
          <div className="static-text">
            <span>{"Hello,"}</span>
            <span>{"Here is referal details"}</span>
          </div>
          <div className="grid-data">
            {data && (
              <>
                {Object.keys(data).map((key) => (
                  <div key={key} className="row-data">
                    <span>
                      {key
                        .replace(/_/g, " ")
                        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
                      :
                    </span>
                    <input
                      type="text"
                      value={data[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      {error && (
        <span className="error">
          {"Select customer to send referral email"}
        </span>
      )}
      <div className="bottom-div">
        <button
          className={
            disabled ? "question-button-yes-disable" : "question-button-yes"
          }
          onClick={handleSendRefferal}
          disabled={disabled}
        >
          {common.SEND}
        </button>
      </div>
    </div>
  );
};

export default SendRefferal;
