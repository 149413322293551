import React from 'react'
import './ExerciseModule.scss'
import '../JournalEntry/JournalEntry.scss'
import { ApiLesson } from '../../../interfaces/MemberPortal'
import { ExerciseTypes, LessonTypes } from '../../../utils/member-portal/constant'
import VideoModule from './VideoViewer'
import SingleJournalEntry from '../JournalEntry/SingleJournalEntry'
import TitleDescription from '../TitleDescription/TitleDescription'
import JourneyList from '../JournalEntry/JournalList'
import { CircularProgress } from '@mui/material'
import ChartedJournal from '../ChartedJournal/ChartedJournal'
import QuestionnaireQues from '../Questionnaire/QuestionnaireQues'
import { updateLessonProgress } from '../../../utils/ApiClient'
import { getNextLesson } from '../../../utils/helperFunctions'
import { FetchApiData } from '../../../services/useFetchData'
import useGlobalState from '../../../context/useGlobalState'

interface ExperciseProps {
    lessonDetails: ApiLesson;
}

const ExerciseModule: React.FC<ExperciseProps> = ({ lessonDetails }) => {
    const { fetchingLesson, cbtModuleSections, globalErrorHandler } = useGlobalState()
    const { fetchLesson } = FetchApiData()

    const handleNextButton = async (id: number, status: boolean): Promise<void> => {
        try {
            await updateLessonProgress(id, status)
            const nextLessonId = getNextLesson(cbtModuleSections, lessonDetails.id)
            if (nextLessonId !== null) {
                fetchLesson(nextLessonId.lessonId)
            }
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    return (
        <div className='exercise-module-component'>
            {fetchingLesson ?
                <div className='loader-box'><CircularProgress /></div> :
                <>
                    {
                        lessonDetails?.lesson_type === LessonTypes.Video && (
                            <VideoModule
                                title={lessonDetails.title}
                                description={lessonDetails.description}
                                url={lessonDetails.mux_video_play_id}
                                onNextButtonClick={() => handleNextButton(lessonDetails.id, true)} />)
                    }
                    {
                        lessonDetails?.lesson_type === LessonTypes.Exercise && (
                            <>
                                {
                                    lessonDetails.exercise_type === ExerciseTypes.scoredQuestionnaire ? null :
                                        <TitleDescription
                                            title={lessonDetails.title}
                                            description={lessonDetails.description}
                                            onNextButtonClick={() => handleNextButton(lessonDetails.id, true)} />
                                }
                                {
                                    lessonDetails.exercise_type === ExerciseTypes.multipleJournalEntry &&
                                    <JourneyList
                                        lessonId={lessonDetails.id}
                                        questions={lessonDetails.questions}
                                        entries={lessonDetails.submitted_entries} />
                                }
                                {
                                    lessonDetails.exercise_type === ExerciseTypes.singleJournalEntry &&
                                    <SingleJournalEntry
                                        lessonId={lessonDetails.id}
                                        questions={lessonDetails.questions}
                                        entries={lessonDetails.submitted_entries} />
                                }
                                {
                                    lessonDetails.exercise_type === ExerciseTypes.chartedJournalEntry &&
                                    <ChartedJournal
                                        lessonId={lessonDetails.id}
                                        questions={lessonDetails.questions}
                                        entries={lessonDetails.submitted_entries} />
                                }
                                {
                                    lessonDetails.exercise_type === ExerciseTypes.scoredQuestionnaire &&
                                    <QuestionnaireQues
                                        question={lessonDetails.questions}
                                        id={lessonDetails.id}
                                        isCompleted={lessonDetails.is_completed}
                                        title={lessonDetails.title}
                                        description={lessonDetails.description}
                                        onNextButtonClick={() => handleNextButton(lessonDetails.id, true)}
                                    />
                                }
                            </>
                        )
                    }
                </>
            }
        </div >
    )
}

export default React.memo(ExerciseModule)