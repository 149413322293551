import React, { useState, useEffect } from 'react'
import './ChartedJournal.scss'
import DateAndTimeInput from '../Input/DateAndTimeInput'
import ExerciseInput from '../Input/ExerciseInput'
import IncrementDecrementButton from '../Counter/IncrementDecrementButton'
import SmallGreenButton from '../Button/SmallGreenButton'
import ScheduleTable from '../Table/ScheduleTable'
import { ApiAnswers, ApiQuestions } from '../../../interfaces/MemberPortal'
import { QuestionTypes } from '../../../utils/member-portal/constant'
import { AnswerType, getAnswerDefaultValue } from '../JournalEntry/JournalEntry'
import { formatTime, validateAnswers } from '../../../utils/helperFunctions'
import { deleteJournalAnswer, editJournalAnswer, submitExerciseAnswers } from '../../../utils/ApiClient'
import { FetchApiData } from '../../../services/useFetchData'
import ConfirmDialogBox from '../DialogBox/ConfirmDialogBox'
import LongTextQuestion from './LongTextQuestion'
import DateAndTimeQuestion from './DateAndTimeQuestion'
import SuccessMessage from '../SuccessMessage/SuccessMessage'
import useGlobalState from '../../../context/useGlobalState'
import LoadingButton from '../Button/LoadingButton'

interface ChartedJournalProps {
    entries: ApiQuestions[];
    lessonId: number,
    questions: ApiAnswers[]
}

const ChartedJournal: React.FC<ChartedJournalProps> = ({ entries, lessonId, questions }) => {
    const [question, setQuestion] = useState<ApiAnswers[]>(questions);
    const [openDialogBox, setOpenDialogBox] = useState(false)
    const [entryId, setEntryId] = useState<number | null>(null)
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>("")
    const [editEntry, setEditEntry] = useState<boolean>(false)
    const [tempAnswers, setTempAnswers] = useState<AnswerType[]>([])
    const [answers, setAnswers] = useState<AnswerType[]>(() => {
        const savedAnswers = localStorage.getItem("savedAnswers");
        return savedAnswers ? JSON.parse(savedAnswers) : [];
    });
    const { globalErrorHandler } = useGlobalState()
    const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [changeName, setChangeName] = useState<boolean>(false)
    const { fetchLesson } = FetchApiData()

    const handleInputChange = (
        id: number,
        value: string | number | null | { date: Date | null, time: Date | null | string },
        isRequired: boolean,
        question: string
    ) => {
        const formattedValue =
            value instanceof Date
                ? { date: value, time: null }
                : value;
        setError(false);
        const updatedAnswers = tempAnswers.map((answer: AnswerType) =>
            answer.question_id === id
                ? { ...answer, answer: formattedValue, is_required: isRequired, question: question }
                : answer
        );
        setTempAnswers(updatedAnswers);
    };

    useEffect(() => {
        setQuestion(questions);
        if (entries.length) {
            const allSubmittedAnswers = entries.flatMap((entry) => entry.submitted_answers);
            setTempAnswers(
                allSubmittedAnswers.map((value) => ({
                    question: value.question,
                    question_id: value.id,
                    answer: value.answers?.answer
                        ? (
                            value.question_type === QuestionTypes.dateAndTime
                                ? {
                                    date: value.answers.answer.date ? new Date(value.answers.answer.date) : new Date(),
                                    time: value.answers.answer.time ? value.answers.answer.time : null,
                                }
                                : value.answers.answer
                        )
                        : getAnswerDefaultValue(value.question_type),
                    is_required: value.is_required,
                }))
            );
        } else {
            setTempAnswers(
                questions.map((value) => ({
                    question: value.question,
                    question_id: value.id,
                    answer: value.answers?.answer
                        ? (
                            value.question_type === QuestionTypes.dateAndTime
                                ? {
                                    date: value.answers.answer.date ? new Date(value.answers.answer.date) : new Date(),
                                    time: value.answers.answer.time ? value.answers.answer.time : null,
                                }
                                : value.answers.answer
                        )
                        : getAnswerDefaultValue(value.question_type),
                    is_required: value.is_required,
                }))
            );
        }
    }, [questions]);

    const editJournalEntry = (_entryId: number) => {
        setEntryId(_entryId)
        setEditEntry(!editEntry)
    }

    const closeDialogBox = () => {
        setIsButtonDisable(false)
        setOpenDialogBox(false)
    }

    const removejouralEntry = async (): Promise<void> => {
        try {
            localStorage.removeItem('savedAnswers')
            const response = await deleteJournalAnswer(entryId ?? 0)
            setSuccessMessage(response.data.message);
            setTimeout(() => {
                fetchLesson(lessonId)
            }, 3000)
            closeDialogBox()
        } catch (error) {
            closeDialogBox()
            globalErrorHandler(error)
        }
    }

    const deleteEntry = (entry_id: number) => {
        setEntryId(entry_id)
        setOpenDialogBox(true)
    }

    const handleSaveJournal = async (): Promise<void> => {
        if (!entries.length) {
            const cleanedData = tempAnswers.map(({ question, is_required, ...rest }) => rest);
            const hasError = validateAnswers(tempAnswers)
            if (!hasError) {
                setError(false)
                setLoading(true)
                try {
                    const response = await submitExerciseAnswers(lessonId, cleanedData);
                    setChangeName(true)
                    setTimeout(() => {
                        setLoading(false)
                        setSuccessMessage(response.data.message)
                        fetchLesson(lessonId)
                    }, 3000)
                    if (response.data.status === 201) {
                        localStorage.setItem("savedAnswers", JSON.stringify([...tempAnswers]));
                        setAnswers([...tempAnswers]);
                    }
                } catch (error) {
                    globalErrorHandler(error);
                }
            } else {
                setErrorMessage(hasError);
                setError(true);
            }
        } else {
            const cleanedData = tempAnswers.map(({ question, is_required, ...rest }) => rest);
            setLoading(true)
            try {
                const response = await editJournalAnswer(lessonId, entryId ?? 0, cleanedData);
                setSuccessMessage(response.data.message);
                setChangeName(true)
                setTimeout(() => {
                    setLoading(false)
                    setSuccessMessage(response.data.message)
                    fetchLesson(lessonId)
                }, 3000)
                if (response.data.status === 200) {
                    localStorage.setItem("savedAnswers", JSON.stringify([...tempAnswers]));
                    setAnswers([...tempAnswers]);
                }
            } catch (error) {
                globalErrorHandler(error);
            }
        }
    };

    return (
        <div className='charted-journal-component'>
            {
                !entries.length ? <>
                    {
                        question?.map((item: ApiAnswers, index) => {
                            return <div key={"entry" + index}>
                                {
                                    item.question_type === QuestionTypes.dateAndTime && <>
                                        <div className='journal-header'>
                                            <div className='title-button-bar'>
                                                <span className='input-title'>Mood Entry</span>
                                            </div>
                                            <DateAndTimeInput
                                                hideTitle={true}
                                                required={item.is_required}
                                                title={item.question}
                                                hideButton={true}
                                                id={item.id}
                                                value={{
                                                    date: item?.answers?.answer ? new Date(item.answers.answer.date) : new Date(),
                                                    time: item?.answers?.answer ? item.answers.answer.time : new Date()
                                                }}
                                                handleInputChange={handleInputChange}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    item.question_type === QuestionTypes.longText && <>
                                        {
                                            <>
                                                <ExerciseInput
                                                    handleInputChange={handleInputChange}
                                                    name={item.question}
                                                    required={item.is_required}
                                                    id={item.id}
                                                    value={(item?.answers?.answer || '') as string}
                                                    title={item.question} />
                                            </>
                                        }
                                    </>
                                }
                                {
                                    item.question_type === QuestionTypes.counter && <>
                                        {
                                            <IncrementDecrementButton
                                                handleInputChange={handleInputChange}
                                                title={item.question}
                                                id={item.id}
                                                required={item.is_required}
                                                answer={tempAnswers[index]}
                                            />
                                        }
                                    </>
                                }
                            </div>
                        })
                    }
                </> : <>
                    {
                        entries?.map((entry: ApiQuestions, index) => {
                            return <div key={"entry" + index}>
                                {
                                    entry.submitted_answers?.map((item, index) => {
                                        return <div key={"item" + index}>
                                            {
                                                item.question_type === QuestionTypes.dateAndTime &&
                                                <DateAndTimeQuestion
                                                    item={item}
                                                    editEntry={editEntry}
                                                    editJournalEntry={() => editJournalEntry(entry.id)}
                                                    removeEdit={() => editJournalEntry(entry.id)}
                                                    deleteEntry={() => deleteEntry(entry.id)}
                                                    formatTime={formatTime}
                                                    handleInputChange={handleInputChange} />
                                            }
                                            {
                                                item.question_type === QuestionTypes.longText && <>
                                                    <LongTextQuestion item={item} editEntry={editEntry} handleInputChange={handleInputChange} />
                                                </>
                                            }
                                            {
                                                item.question_type === QuestionTypes.counter && <>
                                                    {
                                                        (item?.answers?.answer || item?.answers?.answer === 0) && !editEntry ? (
                                                            <div className='count-ques-ans'>
                                                                <span className='input-title'>{item?.question} : </span>
                                                                <span className='input-title'>{item?.answers?.answer || 0}</span>
                                                            </div>
                                                        ) : (
                                                            <IncrementDecrementButton
                                                                handleInputChange={handleInputChange}
                                                                title={item.question}
                                                                id={item.id}
                                                                required={item.is_required}
                                                                answer={tempAnswers[index]}
                                                            />
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                </>
            }
            {
                error && errorMessage && <span className='error'>{errorMessage}</span>
            }
            {
                successMessage && <SuccessMessage message={successMessage} />
            }
            {
                openDialogBox && <ConfirmDialogBox
                    title='Are you sure want to delete journal entry?'
                    content=""
                    confirmLabel={"Confirm"}
                    onConfirm={removejouralEntry}
                    handleClose={closeDialogBox}
                    openDialogBox={openDialogBox}
                    buttonDisabled={isButtonDisable} />
            }
            {
                loading ? <LoadingButton changeName={changeName} /> : <SmallGreenButton name='Submit' onClick={handleSaveJournal} />
            }
            <ScheduleTable answers={answers}
            />
        </div>
    )
}

export default ChartedJournal