import { getLesson } from "../utils/ApiClient";
import useGlobalState from "../context/useGlobalState";

export const FetchApiData = (): {
    fetchLesson: (id: number | null) => void;
} => {
    const { globalErrorHandler, setLessonDetails, setLoader, setExerciseId, setFetchingLesson } = useGlobalState()
    const fetchLesson = async (id: number | null): Promise<void> => {
        if (!id) return
        setFetchingLesson(true)
        try {
            const response = await getLesson(id)
            setLessonDetails(response.data.data)
            setExerciseId(response.data.data.id)
            setFetchingLesson(false)
            setLoader(false)
        } catch (error) {
            setFetchingLesson(false)
            setLoader(false)
            globalErrorHandler(error)
        }
    }
    return { fetchLesson };
}