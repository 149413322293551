import './Module.scss'
import PlayIcon from '../../component/assests/icons/play.png'
import SlackIcon from '../../component/assests/icons/slack.png'
import BookIcon from '../../component/assests/icons/book.png'
import PeopleIcon from '../../component/assests/icons/two-people.png'
import DigitalContentCard from '../../component/common/Cards/DigitalContentCard'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react'
import { getModuleDetails, getModuleDigitalContent } from '../../utils/ApiClient'
import { ApiArticle, ApiCBTModule } from '../../interfaces/MemberPortal'
import Loader from '../../component/common/Loader/Loader'
import SectionCard from '../../component/common/Cards/SectionCard'
import useGlobalState from '../../context/useGlobalState'
import IndividualSupport from '../../sections/IndividualSupport'

const MentalHealth = () => {
    const [modules, setModules] = useState<ApiCBTModule[]>([])
    const [details, setDetails] = useState<ApiCBTModule>({} as ApiCBTModule);
    const [digitalContent, setDigitalContent] = useState<ApiArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const { id } = useParams<{ id: string }>();
    const moduleId = id ? parseInt(id, 10) : null;
    const { globalErrorHandler } = useGlobalState()
    const navigate = useNavigate()
    const fetchModuleData = async (): Promise<void> => {
        try {
            const [moduleDetailsResponse, digitalContentResponse] = await Promise.all([
                getModuleDetails(moduleId),
                getModuleDigitalContent(moduleId),
            ]);
            setModules(moduleDetailsResponse.data.data.cbt_modules);
            setDetails(moduleDetailsResponse.data.data);
            setDigitalContent(digitalContentResponse.data.data.digital_content);
            setLoading(false)
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    useEffect(() => {
        if (moduleId) {
            fetchModuleData();
        }
    }, [moduleId]);

    const handleNavigation = () => {
        navigate(`/section/${moduleId}`)
    }

    return (
        <>{
            loading ? <Loader /> : <div className='mental-health-page'>
                <img src={details.thumbnail_url} alt='cover-img' className='cover-image' />
                <div className='container'>
                    <div className='page-header-section'>
                        <span className='page-title'>{details.name}</span>
                        <div className='right-section'>
                            <span className='health-page-content'>{details.description}</span>
                            <div className='action-buttons-grid' >
                                <div className='action-buttons'>
                                    <img src={PlayIcon} alt='play-icon' className='mini-icons' />
                                    <span>{details.digital_content_count} Modules</span>
                                </div>
                                <div className='action-buttons'>
                                    <img src={SlackIcon} alt='play-icon' className='mini-icons' />
                                    <span>{details.video_count} Videos</span>
                                </div>
                                <div className='action-buttons'>
                                    <img src={BookIcon} alt='play-icon' className='mini-icons' />
                                    <span>{details.exercise_count} Articles</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cards-container'>
                        {
                            modules.map((item: ApiCBTModule, index) => {
                                return (
                                    <SectionCard
                                        key={index + "act_card"}
                                        id={item.id}
                                        title={item.name}
                                        exercise={item.total_exercises}
                                        videos={item.total_videos}
                                        totalMins={item.total_minutes}
                                        remainingMins={item.remaining_mintues}
                                        isStarted={item.is_started}
                                        drDetails={item.curator_name}
                                        image={item.thumbnail}
                                        content={item.description}
                                        status={item.status}
                                        startButtonClick={handleNavigation}
                                    />)
                            })
                        }
                    </div>
                    <div className='sub-header'>
                        <img src={BookIcon} alt='digital-book' className='sub-header-icon' />
                        <span className='sub-header-text'>Digital Content</span>
                    </div>
                    <div className='digital-card-grid'>
                        {
                            digitalContent.map((item, index) => {
                                return <DigitalContentCard
                                    key={"card" + index}
                                    id={item.id}
                                    title={item.title}
                                    image={item.photo}
                                    description={item.description}
                                />
                            })
                        }
                    </div>
                </div>
                <IndividualSupport title='Resources' icon={PeopleIcon} />
            </div>
        }</>
    )
}

export default MentalHealth