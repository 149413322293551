import React from 'react'
import './ExcerciseInput.scss'

interface InputProps {
    id: number
    name?: string;
    title: string;
    required: boolean;
    value: any,
    handleInputChange?: (id: number, value:  string | null | number | {date:null,time:null}, required: boolean, question : string) => void;
}

const ExerciseInput: React.FC<InputProps> = ({ title, id, required, value, handleInputChange }) => {
    return (
        <div className='exercise-input-component'>
            <span className='input-title'>{title}</span>
            <textarea
                className='exercise-input'
                rows={5}
                placeholder='Input here'
                required={required}
                defaultValue={value}
                onChange={(event) => handleInputChange?.(id, event.target.value, required, title)}
            />
        </div>
    )
}

export default ExerciseInput