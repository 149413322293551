import React from 'react'
import './QuestionnaireScore.scss'
import QuestionnarieTitleDesc from './QuestionnarieTitleDesc'
import SmallGreenButton from '../Button/SmallGreenButton'
import { ApiLessonScore } from '../../../interfaces/MemberPortal'

interface ScoreProps {
  scoreObj: ApiLessonScore
}

const QuestionnaireScore: React.FC<ScoreProps> = ({ scoreObj }) => {
  return (
    <div className='questionnaire-score-component'>
      <QuestionnarieTitleDesc
        title='GAD-7 Anxiety'
        description='Your GAD-7 results will determine the severity of your anxiety and future advisements for modules to take next. ' />
      <span className='input-title'>Your GAD-7 Score is</span>
      <span className='module-common-title'> {scoreObj?.score}, {scoreObj?.anxiety_level}</span>
      <SmallGreenButton name='Finish Module ' />
    </div>
  )
}

export default QuestionnaireScore