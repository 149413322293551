import { Link } from 'react-router-dom';
import './DigitalContentCard.scss'
import React from 'react';

interface CardProps {
    id: string | number;
    title: string;
    image: string;
    description: string
}

const DigitalContentCard: React.FC<CardProps> = ({ title, image, id, description }) => {
    return (
        <div className='digital-content-card-component'>
          <Link to={`/content-media/${id}`} className='no-underline'>
            <div className='digital-card-component'>
                <img className='digital-card-img' src={image} alt='digital' />
                <div className='digital-card-content'>
                    <span className='digitalcard-name'>{title}</span>
                    <span className='dig-card-description'>{description}</span>
                    <Link to={`/content-media/${id}`} className='no-underline'><span className="card-link">Read more</span></Link>
                </div>
            </div>
          </Link>
        </div>
    )
}

export default DigitalContentCard