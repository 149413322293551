import React from 'react'
import LoadingIcon from '../../assests/icons/loading.png'

interface ButtonProps {
    changeName: boolean
}

const LoadingButton: React.FC<ButtonProps> = ({ changeName }) => {
    return (
        <button className='green-border-button'>{changeName ? "Saved!" : <img src={LoadingIcon} alt='loading' />}</button>
    )
}

export default LoadingButton