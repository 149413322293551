import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import AppRouter from "./routes/AppRouter";
import { exploreTopicsCategories } from "./utils/ApiClient";
import useGlobalState from "./context/useGlobalState";

const App: React.FC = () => {
  const { globalErrorHandler, setCategories } = useGlobalState()
  const location = useLocation();
  const effectRef = useRef(false)

  const fetchCategories = async (): Promise<void> => {
    try {
      const response = await exploreTopicsCategories();
      setCategories(response.data.data)
    } catch (error) {
      globalErrorHandler(error)
    }
  }

  useEffect(() => {
    if (effectRef.current === false) {
      fetchCategories()
    }
    return () => {
      effectRef.current = true
    }
  }, [])

  useEffect(() => {
    if (location.pathname.startsWith('/exercise')) {
      document.body.classList.add('route-active');
      return () => {
        document.body.classList.remove('route-active');
      };
    }
  }, [location]);


  return (
    <AppRouter />
  );
};

export default App;
