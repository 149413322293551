import React, { useEffect, useRef } from 'react';
import './ModuleInterface.scss'
import ModuleSidebar from '../SideTabs/module-sidebar/ModuleSidebar'
import { useParams, useLocation } from 'react-router-dom';
import { getCBTSectionLessons } from '../../../utils/ApiClient';
import Loader from '../Loader/Loader';
import { FetchApiData } from '../../../services/useFetchData';
import ExerciseModule from './ExerciseModule';
import { findFirstIncompleteLesson } from '../../../utils/helperFunctions';
import useGlobalState from '../../../context/useGlobalState';

const ModuleInterface: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const exerciseId = id ? parseInt(id, 10) : null;
  const location = useLocation();
  const { lessonID } = location.state || {};
  const effectRef = useRef(false)
  const { fetchLesson } = FetchApiData()
  const { setcbtModuleSections, globalErrorHandler, setLessonId, loader, setLoader, lessonDetails } = useGlobalState()

  useEffect(() => {
    const fetchSections = async (): Promise<void> => {
      try {
        const response = await getCBTSectionLessons(exerciseId)
        setcbtModuleSections(response.data.data)
        const sectionIds = findFirstIncompleteLesson(response.data.data)
        setLessonId(sectionIds?.exerciseId ?? null)
        fetchLesson(lessonID ? lessonID : sectionIds?.lessonId ?? null)
        setLoader(false)
      } catch (error) {
        setLoader(false)
        globalErrorHandler(error)
      }
    }
    if (effectRef.current === false) {
      fetchSections()
    }
    return () => {
      effectRef.current = true
    }
  }, [])

  return (
    <>
      {
        loader ? <Loader /> : <div className='module-interface-component'>
          <div className='sidebar-grid sidebar-section'><ModuleSidebar id={Number(id)} /></div>
          <div className='module-content-grid'>
            <ExerciseModule lessonDetails={lessonDetails} />
          </div>
        </div>
      }
    </>
  )
}

export default ModuleInterface