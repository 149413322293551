import { getServiceType } from "./ApiClient";
import { storageKeys } from "./constants";

export const getServices = async (oId: any, context: any) => {
  const { globalErrorHandler, setServiceType, setLoader }: any = context;
  try {
    const response: any = await getServiceType(oId);
    if (response.data.data.list_of_available_service.length === 0) {
      window.location.href = "/invalid-link";
    }
    const responseArray = response.data.data.list_of_available_service;
    const convertedArray: any = Array.isArray(responseArray)
      ? responseArray
      : [responseArray];
    setServiceType(convertedArray);
    localStorage.setItem(storageKeys.AvailableService, JSON.stringify(convertedArray));
    setLoader(false);
  } catch (error) {
    setLoader(false);
    globalErrorHandler(error);
  }
};
