import moment from "moment";
import { formatRelative, parseISO } from "date-fns";
import { GridColDef } from "@mui/x-data-grid";
import CredentialStorage from "./CredentialStorage";
import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys, supportRequest, common, DateRangeOption } from "./constants";
import { AnswerType } from "../component/common/JournalEntry/JournalEntry";
import { Sections } from "../interfaces/MemberPortal";

//Function for create channel-name in sendbird-chat
function channelName(): string {
  const currentDate = moment();
  const day = currentDate.date();
  const year = currentDate.year();
  const monthName = currentDate.format("MMMM");
  const firstName = localStorage.getItem(storageKeys.FirstName) || "";
  const channelName = `${firstName} ${monthName} ${day}, ${year}`;
  return channelName;
}

//Function to calcualte age of User
export function calculateAge(birthdate: string): number {
  const birthDate = moment(birthdate, "DD-MM-YYYY");
  const age = moment().diff(birthDate, "years");
  return age;
}

//Function to get avatar in chat
export const imageSrc = (msgSender: any) => {
  const userId: string | null = localStorage.getItem(storageKeys.UserID);
  if (msgSender?.userId === userId) {
    return `${EnvironmentConfig.imageBaseURL}user.png`;
  } else {
    return `${EnvironmentConfig.imageBaseURL}coach-img.png`;
  }
};

//Function to get date of tickets
export function getFormattedDate(date: Date | string) {
  const momentDate = moment(date);
  const month = momentDate.format("MMMM");
  const day = momentDate.format("DD");
  const year = momentDate.year();
  return `${month}, ${day} ${year}`;
}

export function formateDate(timestamp: any) {
  const date = new Date(timestamp).toISOString();
  const formattedRelativeTime = formatRelative(parseISO(date), new Date(), {
    weekStartsOn: 1,
  });
  return formattedRelativeTime;
}

//Function get user-information for creatinf ticket
export function getTicketData() {
  let eapxValue = CredentialStorage.eapxValues();
  return {
    channel_name: channelName(),
    first_name: localStorage.getItem(storageKeys.FirstName),
    message_content:
      supportRequest.STATIC_MESSAGE +
      localStorage.getItem(storageKeys.TextCoachingQuestion),
    age: eapxValue.age,
    gender: eapxValue.gender,
    phone_number: eapxValue.phone_number,
    state: eapxValue.state,
    company: eapxValue.company,
  };
}

//Function to get Queryparameters for redirection-url
export function getQueryParams() {
  const firstName: string | null =
    localStorage.getItem(storageKeys.FirstName) || "";
  const lastName: string | null =
    localStorage.getItem(storageKeys.LastName) || "";
  const email: string | null = localStorage.getItem(storageKeys.Email) || "";
  const helpForm: string | null =
    localStorage.getItem(storageKeys.HelpForm) || "";
  const company: string | null =
    localStorage.getItem(storageKeys.Company) || "";
  const parsedData = helpForm ? JSON.parse(helpForm) : "";
  const phone = parsedData?.phone_number ? parsedData.phone_number : "";
  const queryParams = `?firstName=${encodeURIComponent(
    firstName
  )}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(
    email
  )}&phone=${encodeURIComponent(phone)}&${EnvironmentConfig.companyNameField
    }=${encodeURIComponent(company)}`;

  return queryParams;
}

export function transformString(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join("-");
}

export function getSimplifiedUserName(input: string): string {
  const parts: string[] = input.split("_");
  const filteredParts: string[] = parts.filter((part) => isNaN(Number(part)));
  const capitalizedParts: string[] = filteredParts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });
  return capitalizedParts.join(" ");
}

export const getClassNameForService = (
  serviceType: string,
  activeSection: string,
  sectionName: string,
  menu: boolean
) => {
  const activeClass = menu ? "active-menu" : "active";
  const commonClass = "menu-items";

  switch (sectionName) {
    case "service1":
      return serviceType?.includes(common.TEXT_COACHING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service2":
      return serviceType?.includes(common.SELF_SCHEDULING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service3":
      return EnvironmentConfig.showWorkLifeRequest &&
        (serviceType?.includes(common.TEXT_COACHING) ||
          serviceType?.includes(common.SELF_SCHEDULING))
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service4":
      return serviceType?.includes(common.TEXT_COACHING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    default:
      return "hide-div";
  }
};

export const getClassNameForServiceVisibility = (
  serviceType: any,
  condition: any,
  isSpecialCondition = false
) => {
  if (isSpecialCondition) {
    return EnvironmentConfig.showWorkLifeRequest &&
      (serviceType?.includes(condition[0]) ||
        serviceType?.includes(condition[1]))
      ? "show-div"
      : "hide-div";
  }
  return serviceType?.includes(condition) ? "show-div" : "hide-div";
};


//Function for eap-case-file to extractTableData from html-string
export const extractTableDataFromString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const rows = doc.querySelectorAll('table tr');
  const rowsData: any = [];

  rows.forEach(row => {
    const cells = row.querySelectorAll('td');
    if (cells.length > 0) {
      const rowData: any = [];
      cells.forEach(cell => {
        rowData.push(cell.textContent);
      });
      rowsData.push(rowData);
    }
  });
  return rowsData
};

export const getDateObjectForCustomDates = (option: DateRangeOption) => {
  const dateObject = {
    fromDate: "",
    toDate: moment().format('YYYY-MM-DD'),
  }
  switch (option) {
    case DateRangeOption.Last24Hours:
      dateObject.fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
      break;
    case DateRangeOption.LastWeek:
      dateObject.fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
      break;
    case DateRangeOption.LastMonth:
      dateObject.fromDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      break;
    case DateRangeOption.Last3Months:
      dateObject.fromDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
      break;
  }
  return dateObject
}

// Combined function to create column definitions with formatted header names
export const dataGridTableColumn = (
  field: string,
  headerName: string,
  minWidth = 150,
  maxWidth = 300
): GridColDef => {
  const formattedHeaderName = headerName
    .replace(/_/g, " ")
    .replace(/(?:^|\s)\S/g, (a: any) => a.toUpperCase());
  return {
    field,
    headerName: formattedHeaderName,
    minWidth,
    maxWidth,
  };
};

export function getFormatDateString(dateString: string): { formattedDate: string, age: number } {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const now = new Date();
  let age = now.getFullYear() - date.getFullYear();
  const monthDifference = now.getMonth() - date.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < date.getDate())) {
    age--;
  }
  return { formattedDate, age };
}

interface QuestionObject {
  question: string;
  answer: string;
}

export function filterCustomQuestions(arr: string[][]): { [key: string]: string } {
  const pattern = /Custom Question \d+:/i;

  const questionObjects = arr
    .map(subArray => {
      const foundQuestion = subArray.find(item => pattern.test(item)) || "";
      const question = foundQuestion.replace(/:$/, "").toLowerCase().replace(/ /g, '_').replace(/[^a-z0-9_]/g, '');
      if (question) {
        let answer = subArray.filter(item => !pattern.test(item)).join(", ");
        return { question, answer };
      }
      return null;
    })
    .filter((item): item is QuestionObject => item !== null);
  return questionObjects.reduce((acc, { question, answer }) => {
    acc[question] = answer;
    return acc;
  }, {} as { [key: string]: string });
}

export function isProviderApproved(caseData: any, caseId: any) {
  if (caseData && Array.isArray(caseData[caseId])) {
    return caseData[caseId].some((item: any) => item.provider_status === 'provider_approved');
  }
  else {
    return false;
  }
}

export function validateRequiredFields(obj: any) {
  const requiredFields = ['full_address', 'provider_detail', 'phone'] as const;

  const fieldNames = {
    full_address: 'Full Address',
    provider_detail: 'Provider Detail',
    phone: 'Phone'
  } as const;

  const missingFields: string[] = [];

  requiredFields.forEach(field => {
    const value = obj[field];
    if (value === null || value === undefined || value.toString().trim() === '') {
      missingFields.push(fieldNames[field]);
    }
  });

  if (missingFields.length > 0) {
    const message = 'The following fields are required: ' + missingFields.join(', ');
    return { valid: false, missingFields, message };
  }

  return { valid: true, message: 'All required fields are present.' };
}

export function extractTimeFromString(dateString: string): string {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const getAppointString = (data: any): string => {
  if (!data) {
    return "-";
  }
  if (typeof data === 'string') {
    return data;
  } else {
    const formattedString = Object.entries(data)
      .map(([key, value]) => {
        if (key === "appointment_date_preference" && typeof value === 'string') {
          return `${key}: ${value}`;
        }
        return `${key}: ${Array.isArray(value) ? value.join(', ') : value}`;
      })
      .join(', ');
    return formattedString.length > 0 ? formattedString : "-";
  }
};



export function extractDateTime(input: string): string {
  const dateObj = new Date(input);
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date format';
  }
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${hours}:${minutes}`;
  return `${formattedDate}; ${formattedTime}`;
}


//Function to capitalize first letter of word
export function capitalizeFirstLetter(word: string) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatDateTime(isoString: string | number): string {
  const date = new Date(isoString);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  const formattedMonth = month.toString();
  const formattedDay = day.toString();
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedMonth}/${formattedDay}/${year} ${hours}:${formattedMinutes} ${amPm}`;
}

export const validateAnswers = (data: AnswerType[]): string | null => {
  for (const { question, is_required, answer } of data) {
    if (is_required) {
      if (answer === null || (typeof answer === 'object' && Object.keys(answer).length === 0) || answer === '') {
        return `${question} is required`;
      }
    }
  }
  return null;
};

export const formatTime = (timeString: string) => {
  const newString = new Date(timeString);
  const hours = newString.getHours();
  const minutes = newString.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  return formattedTime;
};

export function findFirstIncompleteLesson(data: Sections[]) {
  for (const item of data) {
    const firstIncompleteLesson = item.lessons.lessons.find(lesson => !lesson.is_completed);
    if (firstIncompleteLesson) {
      return {
        exerciseId: item.id,
        lessonId: firstIncompleteLesson.id
      };
    }
  }
  return null;
}

export function getNextLesson(data: Sections[], currentLessonId: number) {
  let foundCurrentLesson = false;

  for (const item of data) {
    const lessons = item.lessons.lessons;

    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].id === currentLessonId) {
        lessons[i].is_completed = true;
        foundCurrentLesson = true;

        if (i + 1 < lessons.length) {
          return {
            objectId: item.id,
            lessonId: lessons[i + 1].id
          };
        }
        break;
      }
    }

    if (foundCurrentLesson) {
      const nextItem = data.find(obj => obj.id > item.id);
      if (nextItem) {
        const nextLessons = nextItem.lessons.lessons.find(lesson => !lesson.is_completed);
        if (nextLessons) {
          return {
            objectId: nextItem.id,
            lessonId: nextLessons.id
          };
        }
      }
    }
  }

  return null;
}


