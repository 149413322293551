import React, { useEffect, useState } from "react";
import { Line } from "rc-progress";
import "./ProgressBar.scss";
import { questions, storageKeys } from "../../../utils/constants";

const ProgressBar: React.FC = () => {
  const [percentage, setPercentage] = useState<number>(0);
  useEffect(() => {
    const localStorageKeys = [
      questions.QUESTION_ONE,
      questions.QUESTION_TWO,
      questions.QUESTION_THREE,
      storageKeys.HelpForm,
      storageKeys.TextCoachingQuestion,
    ];

    let newPercentage = percentage;
    localStorageKeys.forEach((key) => {
      if (localStorage.getItem(key)) {
        newPercentage += 20;
      }
    });
    setPercentage(newPercentage);
    localStorage.setItem(
      storageKeys.ProgressPercentage,
      newPercentage.toString()
    );
  }, []);

  return (
    <Line
      percent={percentage}
      strokeWidth={1}
      strokeColor="#2B7272"
      trailColor="#CFF4F7"
      className="progressbar-component"
    />
  );
};

export default ProgressBar;
