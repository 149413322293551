import React from 'react'
import './SupportCard.scss'
import { useNavigate } from 'react-router-dom';
import { common, storageKeys } from '../../../utils/constants';

interface CardProps {
    title: string;
    image: string;
    description: string;
    button: string;
    path: string;
    name: string
}

const SupportCard: React.FC<CardProps> = ({ title, image, description, button, path, name }) => {
    const navigate = useNavigate()
    const handleButtonClick = (path: string, name: string) => {
        if (title === "Get Answers") {
            localStorage.setItem(storageKeys.Flow, "Text Coaching")
            localStorage.setItem(storageKeys.TextCoachingQuestion, common.TEXT_COACHING_OPTION)
        } else {
            localStorage.setItem(storageKeys.Flow, name)
        }
        navigate(path)
    }
    return (
        <div className='support-card-component'>
            <img src={image} className='support-card-img' alt='image' />
            <div className='support-card-content'>
                <span className='support-card-title'>{title}</span>
                <span className='support-description'>{description}</span>
                <button className='support-button' onClick={() => handleButtonClick(path, name)}>{button}</button>
            </div>
        </div>
    )
}

export default SupportCard