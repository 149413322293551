import React, { useState } from 'react'
import { ApiAnswers, ApiQuestions } from '../../../interfaces/MemberPortal';
import JournalEntry from './JournalEntry';
import './JournalList.scss'

interface JournalListProps {
  lessonId: number;
  entries: ApiQuestions[];
  questions: ApiAnswers[];
}

const JournalList: React.FC<JournalListProps> = ({ lessonId, entries, questions }) => {
  const [journalEntries, setJournalEntries] = useState<ApiAnswers[][]>([]);
  const handleAddJournalEntry = () => {
    const newEntry = questions.map((question) => ({
      ...question,
    }));
    setJournalEntries((prevEntries) => [...prevEntries, newEntry]); 
  };
  return (
    <div className='journal-list-component'>
      {
        entries.map((item, index) => {
          return <JournalEntry
            lessonId={lessonId}
            entryId={item.id}
            questions={item.submitted_answers}
            key={"entry" + index}
            isNewEntry={false}
            setJournalEntries={setJournalEntries}
            entryNo={index} />
        })
      }
      {journalEntries.map((entry, index) => (
        <JournalEntry
          isNewEntry={true}
          entryNo={index}
          lessonId={lessonId}
          questions={Array.isArray(entry) ? entry : [entry]} 
          key={`entry-${index}`}
          setJournalEntries={setJournalEntries}
        />
      ))}
      <button className='add-button' onClick={handleAddJournalEntry}>+ Add a new entry</button>
    </div>
  )
}

export default JournalList