import { Navigate } from "react-router-dom";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import { storageKeys } from "../../utils/constants";
import SplashScreen from "./SplashScreen";

const Index = () => {
    const token: string | null = localStorage.getItem(storageKeys.Token);
    if (token) {
        return <Navigate to="/home" />;
    }
        return (
        <FixedHeaderUserLayout children={<SplashScreen />} />
    );
};

export default Index;
