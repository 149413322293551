import { useState, useEffect } from "react";
import {
  selfScheduleQuestion,
  common,
  LS_PHONE_NUMBER,
  callPage,
  storageKeys,
} from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { EmergencyDisclaimer } from "../EmergencyDisclaimer/EmergencyDisclaimer";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import { getQueryParams } from "../../../utils/helperFunctions";
import '../../../styles/customRadioButton.scss'
import './SelfScheduleQuestion.scss'

const Questions = (props: any) => {
  const option = props.option;
  const questions = props.questions;
  const additionalQuestion = props.additionalQuestion;
  const additionalOptions = props.additionalOptions;
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [pageAnswers, setPageAnswers] = useState<any>({});
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>(callPage.NUMBER);

  const handleNavigation = () => {
    if (Object.keys(pageAnswers).length !== 0) {
      //To get the lenght of answered question object
      let answer: [string, any][] = Object.entries(pageAnswers);
      let answerArray: [string, any] = answer[0];
      let answerObject: any = answerArray[1];

      if (Object.keys(answerObject).length === questions.length + 1) {
        if (props.targetPath) {
          localStorage.setItem(
            storageKeys.SSQuestion1,
            JSON.stringify(answerObject)
          );
          navigate(props.targetPath);
        } else {
          localStorage.setItem(
            storageKeys.SSQuestion2,
            JSON.stringify(answerObject)
          );
          const queryParams = getQueryParams();
          window.location.href = `${EnvironmentConfig.selfSchedulingRedirectUrl}${queryParams}`;
        }
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  const handleChange = (option: string, que: string) => {
    const newKey = que;
    const newValue = option;
    setError(false);
    setSelectedOption(option);
    setPageAnswers((prevState: any) => ({
      pageAnswers: {
        ...prevState.pageAnswers,
        [newKey]: newValue,
      },
    }));
  };

  useEffect(() => {
    setPhoneNumber(localStorage.getItem(LS_PHONE_NUMBER) ?? callPage.NUMBER);
  }, []);

  return (
    <div className="self-schedule-question-component page-content">
      <EmergencyDisclaimer phoneNumber={phoneNumber} />
      <span className="subtitle">
        {selfScheduleQuestion.PageOne.TITLE}
      </span>
      <div className="grid-col-two grid_two_response">
        <div className="grid-col-four"></div>
        <div className="grid-col-four">
          {option.map((opt: any, index: number) => {
            return <span key={opt + index}>{opt}</span>;
          })}
        </div>
      </div>
      {questions.map((item: any, index: number) => {
        let que = item.question;
        return (
          <div
            className="grid-col-two que_col_response"
            key={"question" + index}
          >
            <span className="que_name">{item.question}</span>
            <div className="radio-buttons">
              {item.options.map((option: any, index: number) => {
                return (
                  <div className="options" key={"option" + index}>
                    <input
                      type="radio"
                      name={que}
                      className="radio-input"
                      key={index + "option"}
                      value={option}
                      id={index + "-" + que}
                      defaultChecked={selectedOption === option}
                      onChange={() => handleChange(option, que)}
                    />
                    <label htmlFor={index + "-" + que} className="lable_remove">
                      {option}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div
        className="additional-div"
        style={!additionalQuestion ? { display: "none" } : {}}
      >
        <div className="grid-col-two grid_two_response">
          <div className="grid-col-four"></div>
          <div className="grid-col-four">
            {additionalOptions?.map((opt: any, index: number) => {
              return <span key={opt + index}>{opt}</span>;
            })}
          </div>
        </div>
        <div className="grid-col-two que_col_response">
          <span className="que_name">{additionalQuestion}</span>
          <div className="radio-buttons">
            {additionalOptions?.map((option: any, index: number) => {
              return (
                <div className="options" key={"option" + index}>
                  <input
                    type="radio"
                    name={additionalQuestion}
                    className="radio-input"
                    key={index + "option"}
                    value={option}
                    id={index + "-" + additionalQuestion}
                    defaultChecked={selectedOption === option}
                    onChange={() => handleChange(option, additionalQuestion)}
                  />
                  <label
                    htmlFor={index + "-" + additionalQuestion}
                    className="lable_remove label-text"
                  >
                    {option}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {error && (
        <span className="error">
          {"Please select answer for all the questions."}
        </span>
      )}
      <div className="button-box" >
        <button
          className="green-rounded-btn"
          onClick={handleNavigation}
        >
          {common.NEXT}
        </button>
      </div>
    </div>
  );
};

export default Questions;
