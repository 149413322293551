import { NavLink, useLocation } from 'react-router-dom';
import { NavBarLinks } from '../../../utils/member-portal/constant'
import ExpandIcon from '../../assests/icons/expand-icon.png'
import ExpandMenu from '../../assests/icons/expand-menu.png'
import CloseMenuIcon from '../../assests/icons/close-menu.png'
import UserAvatar from '../../assests/icons/user-avatar-green.png'
import useGlobalState from '../../../context/useGlobalState';
import UserProfileMenu from './UserProfileMenu'
import './NavBar.scss'
import { storageKeys } from '../../../utils/constants';

const NavBar = () => {
    const location = useLocation();
    const { isMenuExpanded, setIsMenuExpanded, setOpenProfileMenu, openProfileMenu, selectedNavMenu, setSelectedNavMenu } = useGlobalState();
    const firstName = localStorage.getItem(storageKeys.FirstName)
    const lastName = localStorage.getItem(storageKeys.LastName)

    const handleMouseEnter = (name: string) => {
        if (name !== NavBarLinks.EXPLORE_TOPIC.name) {
            if (isMenuExpanded) {
                setIsMenuExpanded(false)
            }
            return
        }
        setIsMenuExpanded(true)
    }

    const onMenuClick = (name: string) => {
        setIsMenuExpanded(false)
        setSelectedNavMenu(name)
    }

    return (
        <nav className='navbar-component'>
            {Object.entries(NavBarLinks).map(([key, value]) => {
                return (
                    <NavLink to={value.path} className="no-underline" key={key}>
                        <div className={selectedNavMenu === value.name || location.pathname === value.path ? 'link-buttons selected-menu' : 'link-buttons'}
                            onClick={() => onMenuClick(value.name)}
                            onMouseEnter={() => handleMouseEnter(value.name)}>
                            <span>{value.name}</span>
                            {value.name === NavBarLinks.EXPLORE_TOPIC.name && <img src={ExpandMenu} className='menu-icon' />}
                        </div>
                    </NavLink>
                )
            })}
            <div className='logged-in-user' onClick={() => setOpenProfileMenu(!openProfileMenu)}>
                <img src={UserAvatar} alt='user-avatar' />
                <span className='user-name'>{firstName} {lastName}</span>
                <img src={openProfileMenu ? CloseMenuIcon : ExpandIcon} alt='expand' />
            </div>
            {
                openProfileMenu && <UserProfileMenu />
            }
        </nav>
    )
}

export default NavBar