import React from 'react'
import './CourseModule.scss'
import CourseListItem from '../../../CourseSection/CourseListItem'
import { ApiLesson, Sections } from '../../../../../interfaces/MemberPortal'
import DownArrowIcon from '../../../../../component/assests/icons/down-arrow.png'
import LeftArrowIcon from '../../../../../component/assests/icons/left-arrow-black.png'
import useGlobalState from '../../../../../context/useGlobalState'

interface CourseModuleProps {
    list: Sections;
    lessonId?: number;
}

const CourseModule: React.FC<CourseModuleProps> = ({ list, lessonId }) => {
    const { setLessonId } = useGlobalState();

    const handleToggle = (id: number) => {
        if (id === lessonId) {
            setLessonId(null)
        } else
            setLessonId(id)
    }

    return (
        <div className='course-module-component'>
            <div className='module-title-row' onClick={() => handleToggle(list.id)}>
                <span className='module-title-header'>{list.name}</span>
                <img src={list.id === lessonId ? DownArrowIcon : LeftArrowIcon} alt='icon' className='module-header-icon' />
            </div>
            {list.id === lessonId && (
                <div className='module-course-list'>
                    {list.lessons.lessons.map((item: ApiLesson, index: number) => (
                        <CourseListItem
                            key={'item' + index}
                            item={item}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default CourseModule