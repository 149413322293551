import ContentDescriptionCard from '../../component/common/Cards/ContentDescriptionCard'
import './ModuleSection.scss'
import CourseSection from '../../component/common/CourseSection/CourseSection'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { getCBTModule, updateModuleProgress } from '../../utils/ApiClient'
import { ApiCBTModule } from '../../interfaces/MemberPortal'
import Loader from '../../component/common/Loader/Loader'
import useGlobalState from '../../context/useGlobalState'
import { ModuleStatus } from '../../utils/member-portal/constant'

const ModuleSection = () => {
  const { id } = useParams<{ id: string }>();
  const courseId = id ? parseInt(id, 10) : null;
  const effectRef = useRef(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [moduleDetails, setModuleDetails] = useState<ApiCBTModule>({} as ApiCBTModule)
  const { globalErrorHandler } = useGlobalState()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchCBTModules = async (): Promise<void> => {
      try {
        const response = await getCBTModule(courseId)
        setModuleDetails(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        globalErrorHandler(error)
      }
    }
    if (effectRef.current === false) {
      fetchCBTModules()
    }
    return () => { effectRef.current = true }
  }, [])


  const handleModuleProgress = async (): Promise<void> => {
    try {
      await updateModuleProgress(courseId ?? null, ModuleStatus.inProgress)
      navigate(`/exercise/${courseId}`)
    } catch (error) {
      globalErrorHandler(error)
    }
  }

  return (
    <>
      {
        loading ? <Loader /> : <div className='module-section-page'>
          <img src={moduleDetails.thumbnail} className='module-cover-image' />
          <ContentDescriptionCard
            id={moduleDetails.id}
            path='exercise'
            title={moduleDetails.name}
            content={moduleDetails.description}
            isStarted={moduleDetails.is_started}
            exercise={moduleDetails.total_exercises}
            videos={moduleDetails.total_videos}
            totalMins={moduleDetails.total_minutes}
            status={moduleDetails.status}
            remainingMins={moduleDetails.remaining_mintues}
            drDetails={moduleDetails.curator_name}
            startButtonClick={handleModuleProgress} />
          <div className='container'>
            <CourseSection 
              cbtCourseList={moduleDetails.sections} 
              moduleId={moduleDetails.id} 
              isStarted={moduleDetails.is_started}
              startButtonClick={handleModuleProgress} />
          </div>
        </div>
      }
    </>
  )
}

export default ModuleSection