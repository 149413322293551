import React, { useState } from 'react'
import './DateAndTimeInput.scss'
import BinIcon from '../../assests/icons/green-bin.png'
import { TimePicker } from '@mui/x-date-pickers';
import { CCADatePicker } from '../CCADatePicker/CCADatePicker'

interface Props {
    id: number;
    title: string;
    required: boolean;
    hideButton: boolean;
    hideTitle?: boolean;
    value: { date: Date | null, time: null | string};
    handleInputChange: (value1: number, value2: { date: Date | null, time: Date | null | string } | string, isRequired: boolean, question: string) => void;
}

const DateAndTimeInput: React.FC<Props> = ({ title, hideButton, id, value, handleInputChange, hideTitle, required }) => {
    const [newDate, setNewDate] = useState<Date | null>(value.date);
    const [newTime, setNewTime] = useState<Date | null>(value?.time ? new Date(value?.time):null);

    const handleDateChange = (newDate: Date | null) => {
        setNewDate(newDate);
        handleInputChange(id, { date: newDate, time: newTime }, required, title);
    };

    const handleTimeChange = (newTime: Date | null) => {
        setNewTime(newTime)
        const formattedTime = newTime ? newTime : null;
        handleInputChange(id, { date: newDate, time: formattedTime }, required, title);
    };

    return (
        <div className='date-and-time-component'>
            <div className='title-btn-bar'>
                {!hideTitle && <span className='input-title'>{title}</span>}
                {
                    !hideButton && <img src={BinIcon} alt='bin-icon' className='green-bin-icon' />
                }
            </div>
            <div className='input-grid'>
                <CCADatePicker
                    placeholder='Date'
                    value={newDate || new Date()}
                    onChange={handleDateChange} />
                <TimePicker
                    label="Time"
                    value={newTime || new Date() || new Date()}
                    onChange={handleTimeChange}
                />
            </div>
        </div>
    )
}

export default DateAndTimeInput