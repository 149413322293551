import { storageKeys } from "./constants";

export function clearLocalStorageExceptKeys(additionalKey?: string | null) {
  let keepKeys = [
    storageKeys.OrganizationID,
    storageKeys.Token,
    storageKeys.Email,
    storageKeys.FirstName,
    storageKeys.LastName,
    storageKeys.UserID,
    storageKeys.Company,
    storageKeys.UserType,
    storageKeys.AvailableService
  ];
  if (additionalKey) {
    keepKeys.push(storageKeys.TextCoachingQuestion);
  }
  const valuesToKeep: { [key: string]: string | null } = {};
  keepKeys.forEach((key) => {
    valuesToKeep[key] = localStorage.getItem(key);
  });
  localStorage.clear();
  keepKeys.forEach((key) => {
    if (valuesToKeep[key] !== null) {
      localStorage.setItem(key, valuesToKeep[key] as string);
    }
  });
}

export function clearLocalStorageExceptOID(keepKey: any) {
  var valueToKeep: any = localStorage.getItem(keepKey);
  localStorage.clear();
  localStorage.setItem(keepKey, valueToKeep);
}
