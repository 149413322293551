import React, { useEffect, useMemo, useRef, useState } from 'react';
import './ScheduleTable.scss';
import HtmlTooltip from '@mui/material/Tooltip';
import { AnswerType } from '../JournalEntry/JournalEntry';
import { DaysPerWeek } from '../../../utils/member-portal/constant';

type Day = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";

interface TableProps {
  answers: AnswerType[];
  isSubmitted?: boolean;
}

interface TimeSlot {
  time: string;
  activities: Record<Day, { activity: string; moodRating: number } | null>;
}


const ScheduleTable: React.FC<TableProps> = ({ answers }) => {
  const [activities, setActivities] = useState<TimeSlot[]>(DaysPerWeek);
  const [formattedDate, setFormattedDate] = useState<string>("");

  useEffect(() => {

    const initialActivities = DaysPerWeek.map(slot => ({
      ...slot,
      activities: days.reduce((acc, day) => {
        acc[day] = null;
        return acc;
      }, {} as Record<Day, { activity: string; moodRating: number } | null>)
    }));

    const dateTimeQuestion = answers.find((item) => item.question === "Date & Time");
    const situationTrigger = answers.find((item) => item.question === "Situation/Trigger")?.answer || "";
    const moodRating = parseInt(answers.find((item) => item.question === "Mood Rating (1-10)")?.answer as string || "0", 10);

    if (!dateTimeQuestion || typeof dateTimeQuestion.answer !== "object" || dateTimeQuestion.answer === null) {
      setActivities(initialActivities);
      return;
    }

    const { date, time } = dateTimeQuestion.answer as { date: string | Date | null; time: string | Date | null };
    if (!date || !time) {
      setActivities(initialActivities);
      return;
    }

    const dayOfWeek = new Date(date).toLocaleDateString("en-US", { weekday: "long" }) as Day;
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
    setFormattedDate(formattedDate);

    const formattedTime = new Date(time).getHours();
    const updatedActivities = initialActivities.map(slot => {
      const [start, end] = slot.time.split(" to ").map(t => {
        const hour = parseInt(t.replace(/[APM]/g, "").trim(), 10);
        return t.includes("PM") && hour !== 12 ? hour + 12 :
          t.includes("AM") && hour === 12 ? 0 :
            hour;
      });

      if (formattedTime >= start && formattedTime < end) {
        return {
          ...slot,
          activities: {
            ...slot.activities,
            [dayOfWeek]: {
              activity: situationTrigger,
              moodRating: moodRating,
            },
          },
        };
      }
      return slot;
    });

    setActivities(updatedActivities);
  }, [answers]);

  const days: Day[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  return (
    <div className='schedule-table-component'>
      <span className='input-title'>Your current week</span>
      <table>
        <thead className='table-head'>
          <tr>
            <th className='day-title'></th>
            {days.map(day => (
              <th key={day} className='day-header'>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body'>
          {activities.map((slot, index) => (
            <tr key={index}>
              <td className="time-slot-header">{slot.time}</td>
              {days.map(day => {
                const activityObj = slot.activities[day];
                return (
                  <HtmlTooltip
                    key={day}
                    placement='right-end'
                    enterDelay={100}
                    title={
                      <div className='cell-tooltip'>
                        <span className='day-info'>{day} {formattedDate}; {slot.time}</span>
                        <span className='tooltip-content'>Activity : {activityObj?.activity || ""}</span>
                        <span className='tooltip-content'>Mood rating: {activityObj?.moodRating || ""}</span>
                      </div>
                    }
                  >
                    <td className="time-slot" data-day={day} data-time={slot.time}>
                      {activityObj ? `${activityObj.activity} Mood Rating: ${activityObj.moodRating}` : <span>&nbsp;</span>}
                    </td>
                  </HtmlTooltip>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleTable;