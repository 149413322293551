import './ExpandableMenu.scss'
import { useState } from 'react'
import { ApiCareSquadCategory } from '../../../interfaces/MemberPortal'
import ExpandMenu from '../../assests/icons/expand-menu.png'
import { capitalizeFirstLetter } from '../../../utils/helperFunctions'
import useGlobalState from '../../../context/useGlobalState'
import { Link } from 'react-router-dom'

const ExpandableMenu = () => {
  const [isOptionHover, setIsOptionHover] = useState<boolean>(false)
  const [hoveredMenu, setHoveredMenu] = useState<number | null>(null)
  const [hoveredMenuOpt, setHoveredMenuOpt] = useState<number | null>(null)
  const { categories, setIsMenuExpanded } = useGlobalState();

  const handleMouseEnter = (menu: number, item: number) => {
    setHoveredMenu(menu)
    setHoveredMenuOpt(item)
    setIsOptionHover(true)
  }

  return (
    <div className='expand-menu-component' onMouseLeave={() => setIsMenuExpanded(false)} >
      <div className='menu-grid'>
        {
          categories?.map((item: ApiCareSquadCategory, menuIndex: number) => {
            const subCategory = item.subcategory_data
            if (!item.subcategory_data?.length) return
            return (<div className='menu-items' key={"category" + menuIndex}>
              <div className='sm-menu'>
                <span className='title'>{item.label}</span>
                <img src={ExpandMenu} alt='expand-menu' className='expand-menu-icon' />
              </div>
              {subCategory.map((topic, index) => {
                return (
                  <div onMouseEnter={() => handleMouseEnter(menuIndex, index)} className='menu-box' key={"subCat" + index}>
                    <Link to={`/module/${topic.id}`} className='no-underline' onClick={() => setIsMenuExpanded(false)}>
                      <div className='dot-options'>
                        <span className='dots-container'>
                          {
                            hoveredMenu === menuIndex && hoveredMenuOpt === index && isOptionHover
                              ? <span className='dots visible'></span>
                              : <span className='dots hidden'></span>
                          }
                        </span>
                        <span className='options'>{capitalizeFirstLetter(topic.name)}</span>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>)
          })
        }
      </div>
    </div>
  )
}

export default ExpandableMenu