import { useParams } from 'react-router-dom'
import './ContentMedia.scss'
import { useEffect, useRef, useState } from 'react';
import { getContentMedia } from '../../utils/ApiClient';
import { ApiContentMediaData } from '../../interfaces/MemberPortal';
import BackToModule from '../../component/common/BackToModule/BackToModule';
import Loader from '../../component/common/Loader/Loader';
import useGlobalState from '../../context/useGlobalState';

const ContentMedia = () => {
    const { id } = useParams<{ id: string }>();
    const contentId = id ? parseInt(id, 10) : null;
    const [contentDetails, setContentDetails] = useState({} as ApiContentMediaData)
    const [loading, setLoading] = useState<boolean>(true)
    const { globalErrorHandler } = useGlobalState()
    const effectRef = useRef(false)

    useEffect(() => {
        const fetchContentMedia = async (): Promise<void> => {
            try {
                const response = await getContentMedia(contentId ?? 0)
                setContentDetails(response.data.data)
                setLoading(false)
            } catch (error) {
                globalErrorHandler(error)
                setLoading(false)
            }
        }
        if (effectRef.current === false) {
            fetchContentMedia()
        }
        return () => { effectRef.current = true }
    }, [])

    return (
        <>
            {
                loading ? <Loader /> : <div className='content-media-page container'>
                    <BackToModule name='Back to topic' path='/explore-topics' />
                    {
                        contentDetails ? <>
                            <h4 className='media-title'>{contentDetails.title}</h4>
                            <div className='content-columns' >
                                <div className='body-section'>
                                    <img src={contentDetails?.photo}></img>
                                    {contentDetails?.body && (
                                        <div dangerouslySetInnerHTML={{ __html: contentDetails?.body }}></div>
                                    )}
                                </div>
                                {
                                    contentDetails.sources.length !== 0 && <div className='src-section'>
                                        <span className='src-title'>Need More Info?</span>
                                        <span className='src-desc'>For more information on this topic, visit these resources: </span>
                                        <div className='src-links-list'>
                                            {
                                                contentDetails.sources?.map((item, index) => {
                                                    return <div className="src-links" key={"source" + index}>
                                                        <ul>
                                                            <li>
                                                                <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                                    {item.title}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </> : <></>
                    }
                </div>
            }
        </>
    )
}

export default ContentMedia