import './HeroGridImages.scss'
import EnvironmentConfig from '../component/config/EnvironmentConfig'
import { Link } from 'react-router-dom'

const HeroGridImages = () => {
  return (
    <div className='hero-grid-component'>
      <Link to={'/explore-topics'} className='no-underline'><img src={`${EnvironmentConfig.imageBaseURL}explore-topic.png`} className='grid-image' /></Link>
      <Link to={'/individual-support'} className='no-underline'><img src={`${EnvironmentConfig.imageBaseURL}access-support.png`} className='grid-image' /></Link>
      <img src={`${EnvironmentConfig.imageBaseURL}learn-abt-cca.png`} className='grid-image response-hide' />
      <img src={`${EnvironmentConfig.imageBaseURL}leran-abt-small.png`} className='grid-image-response' />
    </div>
  )
}

export default HeroGridImages