import React from 'react';
import EditIcon from '../../assests/icons/edit-icon.png'
import BinIcon from '../../assests/icons/green-bin.png'
import CancelIcon from '../../assests/icons/cancel.png'
import DateAndTimeInput from '../Input/DateAndTimeInput'
import { ApiAnswers } from '../../../interfaces/MemberPortal';

interface DateAndTimeProps {
    item: ApiAnswers;
    editEntry: boolean;
    editJournalEntry: () => void;
    deleteEntry: () => void;
    formatTime: (time: string) => string;
    handleInputChange: (value1: number, value2: { date: Date | null, time: Date | null | string } | string, isRequired: boolean, question: string) => void;
    removeEdit: () => void;
}

const DateAndTimeQuestion: React.FC<DateAndTimeProps> = (props: DateAndTimeProps) => {
    return (
        <>
            {props.item?.answers?.answer && !props.editEntry ? (
                <div className="journal-header">
                    <div className="title-button-bar">
                        <span className="input-title">Mood Entry</span>
                        <div className="icons-section">
                            {props.editEntry ? (
                                <img
                                    src={CancelIcon}
                                    className="green-bin-icon"
                                    onClick={props.removeEdit}
                                    alt="cancel-icon"
                                />
                            ) : (
                                <img
                                    src={EditIcon}
                                    className="green-bin-icon"
                                    onClick={props.editJournalEntry}
                                    alt="edit-icon"
                                />
                            )}

                            <img
                                src={BinIcon}
                                alt="bin-icon"
                                className="green-bin-icon"
                                onClick={props.deleteEntry}
                            />
                        </div>
                    </div>
                    <span className="date-n-time">
                        {props.item.answers.answer.date} {props.formatTime(props.item.answers.answer.time)}
                    </span>
                </div>
            ) : (
                <div className="journal-header">
                    <div className="title-button-bar">
                        <span className="input-title">Mood Entry</span>
                        <div className="icons-section">
                            {props.editEntry && (
                                <img
                                    src={CancelIcon}
                                    className="green-bin-icon"
                                    onClick={props.removeEdit}
                                    alt="cancel-icon"
                                />
                            )}
                            <img
                                src={BinIcon}
                                alt="bin-icon"
                                className="green-bin-icon"
                                onClick={props.deleteEntry}
                            />
                        </div>
                    </div>
                    <DateAndTimeInput
                        hideTitle={true}
                        required={props.item.is_required}
                        title={props.item.question}
                        hideButton={true}
                        id={props.item.id}
                        value={{
                            date: props.item?.answers?.answer
                                ? new Date(props.item.answers.answer.date)
                                : new Date(),
                            time: props.item?.answers?.answer
                                ? props.item.answers.answer.time
                                : new Date(),
                        }}
                        handleInputChange={props.handleInputChange}
                    />
                </div>
            )}
        </>
    );
};

export default DateAndTimeQuestion;
