import React, { useState, useEffect } from 'react'
import DeleteBin from '../../assests/icons/bin.png'
import { addProviderOutReach, assignProvider, deleteProviderOutReach, editProviderOutReach, getEAPCases } from '../../../utils/ApiClient';
import CustomSelectWithButtons from '../../common/DropdownMenu/DropdownWithConfirmationBar';
import { ProviderStatus, providerAssignDialog, deleteProviderDialog, assigningProviderDialog, storageKeys, userType, PRTUserCasesType, CaseStatus, SideBarTabItemType1 } from '../../../utils/constants';
import ConfirmationDialogBox from '../../common/DialogBox/ConfirmDialogBox'
import { validateRequiredFields, extractDateTime } from '../../../utils/helperFunctions';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './AdditionalProvider.scss'
import useGlobalState from '../../../context/useGlobalState';

interface AdditionalProviderProps {
  provider: any;
  providerNo: number;
  activeCase: any;
  setAdditionalProviders: (activeCase: any) => void;
  additionalProviders: any;
  caseStatus: string;
  setCasesTypeName: (caseTpe: string) => void;
  setNoData: (data: boolean) => void;
  setLoading: (loading: boolean) => void;
  setCases: (cases: any) => void;
  setActiveCaseInfo: (caseInfo: any) => void;
  setActiveCase: (activeCase: any) => void;
  casesTypeName: string;
  onCaseTypeTabChange?: (caseType: string) => void;
}

const AdditionalProviderComponent: React.FC<AdditionalProviderProps> = ({ provider, providerNo, activeCase, setAdditionalProviders, caseStatus, setCasesTypeName, casesTypeName, setLoading, onCaseTypeTabChange }) => {
  const [providerInfo, setProviderInfo] = useState<any>({})
  const [errors, setErrors] = useState<string>("");
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openAssignProvider, setOpenAssignProvider] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [status, setStatus] = useState<string | null>("")
  const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)
  const [closingCase, setClosingCase] = useState<boolean>(false)
  const { globalErrorHandler } = useGlobalState()
  const disabled = (casesTypeName === PRTUserCasesType.CLOSED.label)
  const userTypeName = localStorage.getItem(storageKeys.UserType)
  const isManager = userTypeName === userType.PRT_MANAGER

  useEffect(() => {
    setProviderInfo(provider)
  }, [provider])

  useEffect(() => {
    setStatus(provider?.provider_status_display)
  }, [provider?.provider_status_display])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setProviderInfo((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  }

  const removeProvider = (index: number) => {
    setAdditionalProviders((prevProviders: any) => {
      const updatedProviders = { ...prevProviders };
      updatedProviders[activeCase] = [
        ...prevProviders[activeCase].slice(0, index),
        ...prevProviders[activeCase].slice(index + 1)
      ];
      return updatedProviders;
    });
  };

  const saveProviderDetails = async (providerIndex: number): Promise<void> => {
    const result = validateRequiredFields(providerInfo)
    if (!result.valid) {
      setErrors(result.message);
    } else {
      const matchedValue = Object.values(ProviderStatus).find(item => item.label === status);
      const data = {
        eapx_file_number: providerInfo.file_number,
        eapx_case: activeCase,
        provider_detail: providerInfo.provider_detail,
        full_address: providerInfo.full_address,
        email: providerInfo.email,
        phone: providerInfo.phone,
        notes: providerInfo.notes,
        provider_status: matchedValue?.value
      };
      try {
        const response = await addProviderOutReach(data);
        const newProvider = { ...providerInfo, id: response.data.data.id, provider_status: matchedValue, provider_status_display: status, status: "Saved" };
        setProviderInfo(newProvider);
        setAdditionalProviders((prev: any) => {
          const newProviders = { ...prev };
          newProviders[activeCase] = newProviders[activeCase].map(
            (provider: any, index: number) =>
              index === providerIndex ? newProvider : provider
          );
          return newProviders;
        });
      } catch (error) {
        globalErrorHandler(error);
      }
    }
  };

  const changeButtonName = (data: any) => {
    const updatedData = { ...providerInfo, ...data, status: "Save" };
    setProviderInfo(updatedData);
    setAdditionalProviders((prev: any) => {
      const updatedProviders = { ...prev };
      updatedProviders[activeCase] = updatedProviders[activeCase].map((provider: any) =>
        provider.id === providerInfo.id ? updatedData : provider
      );
      return updatedProviders;
    });
  }

  const updateProvider = async (): Promise<void> => {
    const matchedValue = Object.values(ProviderStatus).find((item: SideBarTabItemType1) => item.label === status);
    const data = { ...providerInfo, provider_status: matchedValue?.value, provider_status_display: status };
    delete data.status;
    try {
      await editProviderOutReach(providerInfo.id, data);
      const updatedData = { ...providerInfo, ...data, status: "Saved" };
      setProviderInfo(updatedData);
      setAdditionalProviders((prev: any) => {
        const updatedProviders = { ...prev };
        updatedProviders[activeCase] = updatedProviders[activeCase].map((provider: any) =>
          provider.id === providerInfo.id ? updatedData : provider
        );
        return updatedProviders;
      });
      setTimeout(() => {
        changeButtonName(data)
      }, 5000)
    } catch (error) {
      globalErrorHandler(error);
      setProviderInfo({ ...provider });
    }
  };

  const removeProviderOutreach = async (providerId: number): Promise<void> => {
    setIsDialogButtonDisabled(true)
    try {
      await deleteProviderOutReach(providerId);
      setAdditionalProviders((prev: any) => {
        const updatedProviders = { ...prev };
        updatedProviders[activeCase] = updatedProviders[activeCase].filter(
          (provider: any) => provider.id !== providerId
        );
        return updatedProviders;
      });
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
    } catch (error) {
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
      globalErrorHandler(error);
    }
  };

  const handlePopUp = (index: number) => {
    if (providerInfo.id !== 0) {
      setOpenDeleteDialog(true)
    } else {
      removeProvider(index)
    }
  }

  const handleCancelButtonPressed = () => {
    if (openDeleteDialog) {
      setOpenDeleteDialog(false)
    }
    if (openDialogBox) {
      setOpenDialogBox(false)
    }
    if (openAssignProvider) {
      setOpenAssignProvider(false)
    }
  }

  const assignProviderOutreach = async (id: number): Promise<void> => {
    setIsDialogButtonDisabled(true)
    handleCancelButtonPressed()
    setClosingCase(true)
    try {
      const response = await assignProvider(id)
      const dateAndTime = extractDateTime(response.data.data.assigned_date);
      let _message = "PROVIDER ASSIGNED - " + dateAndTime
      setSuccessMessage(_message)
      setIsDialogButtonDisabled(false)
      setClosingCase(false)
      setLoading(true)
      if(onCaseTypeTabChange) onCaseTypeTabChange(PRTUserCasesType.CLOSED.value)
      setCasesTypeName(PRTUserCasesType.CLOSED.label);
    } catch (error) {
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
      setClosingCase(false)
      globalErrorHandler(error)
    }
  }

  const handleAssign = async (): Promise<void> => {
    const status = ProviderStatus.PROVIDER_ACCEPTED.value
    if (ProviderStatus.PROVIDER_ACCEPTED.value === providerInfo.provider_status) {
      setOpenAssignProvider(true)
    }
    else if (providerInfo.provider_status !== status) {
      setOpenDialogBox(true)
    }
  }

  return (
    <div className='additional-provider-component'>
      <div className='provider-action-header'>
        <span className='provider-title'>Provider #{providerNo + 1}</span>
        {
          successMessage ? <span className='success message-block'>{successMessage}</span> : <div>
            {
              caseStatus === CaseStatus.RE_OPENED.label && provider?.assigned_date ?
                <span className='success message-block'> PROVIDER ASSIGNED - {extractDateTime(provider?.assigned_date)}</span> :
                <div className='buttons-section'>
                  {
                    casesTypeName === PRTUserCasesType.CLOSED.label && caseStatus === CaseStatus.ACCPETED.label && provider?.assigned_date ?
                      <span className='success message-block'> PROVIDER ASSIGNED - {extractDateTime(provider?.assigned_date)}</span> :
                      <div className='dropdown-section'>
                        <CustomSelectWithButtons
                          options={ProviderStatus}
                          name='provider-status'
                          stateChange={setStatus}
                          state={status}
                          showButtons={false}
                          fileNo={providerInfo.file_number}
                          showPopUp={true}
                          casesTypeName={casesTypeName} />
                      </div>
                  }
                  {
                    !(casesTypeName === PRTUserCasesType.CLOSED.label || isManager) && <div className="buttons">
                      <img src={DeleteBin} alt='delete' className='delete-icon' onClick={() => handlePopUp(providerNo)} />
                      <button
                        className='save-button'
                        onClick={() => providerInfo.id > 0 ? updateProvider() : saveProviderDetails(providerNo)}>
                        {provider.status === "Saved" ? "Saved!" : "Save"}
                      </button>
                      <button
                        className='assign-button'
                        onClick={handleAssign}>
                        {"Assign"}
                      </button>
                    </div>
                  }
                </div>
            }
          </div>
        }
      </div>
      <div className='fields'>
        <input type='text' className='input-box' placeholder='First name, last name, license type or group name*' name='provider_detail' value={providerInfo.provider_detail} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className='input-box' placeholder='Tela health or full address*' name='full_address' value={providerInfo.full_address} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className='input-box' placeholder='Phone*' name='phone' value={providerInfo.phone} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className='input-box' placeholder='Email' name='email' value={providerInfo.email} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className='input-box' placeholder='Note' name='notes' value={providerInfo.notes} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
      </div>
      <div>{errors && <span className='error'>{errors}</span>}
      </div>
      <ConfirmationDialogBox
        title={providerAssignDialog.title}
        content={providerAssignDialog.content}
        onConfirm={() => assignProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={providerAssignDialog.confirmLabel}
        openDialogBox={openDialogBox}
        buttonDisabled={isDialogButtonDisabled} />
      <ConfirmationDialogBox
        title={deleteProviderDialog.title}
        content={deleteProviderDialog.content}
        onConfirm={() => removeProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={deleteProviderDialog.confirmLabel}
        openDialogBox={openDeleteDialog}
        buttonDisabled={isDialogButtonDisabled} />
      <ConfirmationDialogBox
        title={assigningProviderDialog.title}
        content={assigningProviderDialog.content}
        onConfirm={() => assignProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={assigningProviderDialog.confirmLabel}
        openDialogBox={openAssignProvider}
        buttonDisabled={isDialogButtonDisabled} />
      <Backdrop open={closingCase} style={{ color: 'white' }}>
        <div className='backdrop-loader'>
          <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.12) ' }} />
          <span>{"CLOSING CASE"}</span>
        </div>
      </Backdrop>
    </div>
  )
}

export default React.memo(AdditionalProviderComponent)