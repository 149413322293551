import { FC, useState } from "react";
import GlobalContext from "./globalContext";
import { clearLocalStorageExceptOID } from "../utils/clearStorageData";
import { useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { storageKeys } from "../utils/constants";
import { ApiLesson, Sections } from "../interfaces/MemberPortal";

type GlobalStateProviderProps = {
  children: React.ReactNode;
};

interface GlobalStateProps {
  globalError: string;
  serviceType: string[] | null;
  loader: boolean;
  menu: boolean;
  openNavMenu: boolean;
  isMenuExpanded: boolean;
  openProfileMenu: boolean;
  categories: [] | undefined;
  selectedNavMenu: string | null | undefined,
  cbtModuleSections: Sections[],
  lessonId: number | null | undefined,
  lessonDetails: ApiLesson,
  exerciseId: number | null | undefined,
  moduleId: number | null,
  fetchingLesson: boolean,
  setServiceType: (serviceType: string[] | null) => void;
  setGlobalError: (error: string) => void;
  globalErrorHandler: (error: any) => void;
  setLoader: (loading: boolean) => void;
  setMenu: (menu: boolean) => void;
  setCategories: (categories: []) => void;
  setOpenNavMenu: (menu: boolean) => void;
  setIsMenuExpanded: (menu: boolean) => void;
  setOpenProfileMenu: (menu: boolean) => void;
  setSelectedNavMenu: (menu: string | null) => void;
  setExerciseId: (id: number | null) => void;
  setModuleID: (id: number | null) => void;
  setFetchingLesson: (fetching: boolean) => void;
  setLessonId: (id: number | null) => void;
  setcbtModuleSections: (sections: Sections[]) => void;
  setLessonDetails: (lesson: ApiLesson) => void;
}

const GlobalState: FC<GlobalStateProviderProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [globalError, setGlobalError] = useState<string>("");
  const [serviceType, setServiceType] = useState<string[] | null>(null);
  const [loader, setLoader] = useState(true);
  const [menu, setMenu] = useState<boolean>(false);
  const [openNavMenu, setOpenNavMenu] = useState<boolean>(false)
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false)
  const [selectedNavMenu, setSelectedNavMenu] = useState<string | null>()
  const [categories, setCategories] = useState<[] | undefined>([]);
  const [cbtModuleSections, setcbtModuleSections] = useState<Sections[]>([]);
  const [moduleId, setModuleID] = useState<number | null>(null)
  const [lessonId, setLessonId] = useState<number | null>();
  const [exerciseId, setExerciseId] = useState<number | null>();
  const [lessonDetails, setLessonDetails] = useState<ApiLesson>({} as ApiLesson)
  const [fetchingLesson, setFetchingLesson] = useState<boolean>(false)

  const globalErrorHandler = (error: any) => {
    if (error.response?.status === 401) {
      clearLocalStorageExceptOID(storageKeys.OrganizationID);
      navigate(`/login?redirect_url=${pathName}`);
    } else if (error.response?.status === 400) {
      setGlobalError(error.response?.data.message);
      Sentry.captureMessage(error.response?.data.message);
    } else if (error.response?.status === 404) {
      setGlobalError(error.response?.data.message);
      Sentry.captureMessage(error.response?.data.message);
    } else {
      setGlobalError(error.message);
      Sentry.captureException(error);
    }
  };

  const globalState: GlobalStateProps = {
    globalError,
    serviceType,
    loader,
    menu,
    openNavMenu,
    isMenuExpanded,
    openProfileMenu,
    categories,
    setCategories,
    selectedNavMenu,
    cbtModuleSections,
    lessonId,
    lessonDetails,
    exerciseId,
    moduleId,
    fetchingLesson,
    setServiceType,
    setGlobalError,
    globalErrorHandler,
    setLoader,
    setMenu,
    setOpenNavMenu,
    setIsMenuExpanded,
    setOpenProfileMenu,
    setSelectedNavMenu,
    setcbtModuleSections,
    setLessonId,
    setLessonDetails,
    setExerciseId,
    setModuleID,
    setFetchingLesson
  };

  return (
    <GlobalContext.Provider value={globalState}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
