import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import MentalHealthCourse from "./ModuleSection";

const index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<MentalHealthCourse />} />
  );
};

export default index;
