import axios from "axios";
import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys } from "./constants";

export const RefreshToken = async (): Promise<any> => {
  const token = localStorage.getItem(storageKeys.Token);
  const sessionToken: any = localStorage.getItem(storageKeys.SessionToken);
  const sessionExpiry: any = localStorage.getItem(storageKeys.SessionExpiry);
  const headers = {
    Authorization: token,
  };
  let parseTime: any = JSON.parse(sessionExpiry);
  let expiryTime = new Date(parseTime);
  let currentTime = new Date();
  const expiryFlag = expiryTime > currentTime;
  if (!expiryFlag) {
    const response = await axios.post(
      `${EnvironmentConfig.ccaApiRoot}/generate/session-token`,
      {},
      { headers: headers }
    );
    if (response.data.status === 201) {
      localStorage.setItem(storageKeys.SessionToken, response.data.data.token);
      localStorage.setItem(
        storageKeys.SessionExpiry,
        response.data.data.expires_at
      );
      return response.data.data.token;
    } else {
      return false;
    }
  } else {
    return sessionToken;
  }
};
