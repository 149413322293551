import ServiceCards from '../../component/common/ServiceComponent/ServiceComponent'
import './IndividualSupport.scss'
import { useNavigationHandler } from '../../utils/useNavigationHandler';
import { storageKeys } from '../../utils/constants';

const IndividualSupport = () => {
  const serviceType = localStorage.getItem(storageKeys.AvailableService)
  const { changedFlowNavigation } = useNavigationHandler();
  const handleNavigation = (flow?: string) => {
    const flowName = flow || "";
    changedFlowNavigation(flowName);
  };

  return (
    <div className='individual-support-page'>
      <div className='container'>
        <ServiceCards
          handleButtons={handleNavigation}
          serviceType={serviceType}
          isLandingPage={true} />
      </div>
    </div>
  )
}

export default IndividualSupport