import React from 'react'
import './TopicCard.scss'
import './LongCradCommonstyles.scss'
import RightArrow from '../../assests/icons/right-side-arrow.png'
import { Link } from 'react-router-dom';

interface TopicProps {
    title: string;
    image: string;
    menuItems: { title: string, src: string, path?: string }[];
    path: string;
}

const TopicCard: React.FC<TopicProps> = ({ title, image, menuItems, path }) => {
    return (
        <div className='topic-card-component'>
            <img className='left-side-img' src={image} />
            <div className='right-content-details'>
                <div className='details-header'>
                    <span className='details-header-title'>{title}</span>
                    <Link className='no-underline' to={path}><span className='learn-link'>Learn more</span></Link>
                </div>
                <div className='details-menu-items' >
                    {
                        menuItems.map((item, index) => {
                            return <div className='detail-item' key={index + "item"}>
                                {
                                    item.path ? <Link to={item.path} className='no-underline link-block'>
                                        <div className='link-detail-item'>
                                            <div className='icon-box'>
                                                <img src={item.src} alt='icon' />
                                                <span className='title-line'>{item.title}</span>
                                            </div>
                                            <img src={RightArrow} alt='arrow' className='arrow-icon' />
                                        </div>
                                    </Link> : <>
                                        <div className='icon-box'>
                                            <img src={item.src} alt='icon' />
                                            <span className='title-line'>{item.title}</span>
                                        </div>
                                        <img src={RightArrow} alt='arrow' className='arrow-icon' /></>
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default TopicCard