import React, { useState } from 'react';
import { MenuItem, Box, Button, Menu } from '@mui/material';
import TickButton from '../../assests/icons/check-button.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { updateEapxCase } from '../../../utils/ApiClient';
import { isProviderApproved } from '../../../utils/helperFunctions';
import { caseStatusChangeDialog, storageKeys, userType, SideBarTabItemType1, SideBarTabItemType2, PRTUserCasesType } from '../../../utils/constants';
import ConfirmationDialogBox from '../DialogBox/ConfirmDialogBox';
import './DropdownWithConfirmationBar.scss'
import useGlobalState from '../../../context/useGlobalState';

interface CustomSelectWithButtonsProps {
  options: {} | [];
  name: string;
  stateChange: (state: string) => void;
  state: any;
  showButtons: boolean;
  fileNo: any;
  showPopUp: boolean;
  dialogTitle?: string;
  onConfirm?: () => void;
  confirmLabel?: string;
  activeCase?: string;
  additionalProviders?: any;
  setSuccessMessage?: (message: any) => void;
  setCaseStatus?: (state: string) => void;
  casesTypeName: string;
  caseStatus?: string;
  specialist? : string;
}

const DropdownWithConfirmationBar: React.FC<CustomSelectWithButtonsProps> = ({ casesTypeName, options, name, stateChange, state, caseStatus, showButtons, fileNo, showPopUp, additionalProviders, activeCase, setSuccessMessage, setCaseStatus, specialist }) => {
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const userTypeName = localStorage.getItem(storageKeys.UserType)
  const isManager = userTypeName === userType.PRT_MANAGER
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuDisabled = (casesTypeName === "Closed" && name !== "eapx_file_status") || isManager ? true : false
  const [valueToSend, setValueToSend] = useState<string>("")
  const { globalErrorHandler } = useGlobalState()
  const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelButtonPressed = () => {
    if (name === "eapx_file_status") {
      if (caseStatus) {
        stateChange(caseStatus)
        if (setCaseStatus) {
          setCaseStatus(caseStatus)
        }
      }
      setOpenDialogBox(false)
      setAnchorEl(null)
    } else {
      stateChange(specialist ?? "")
      setAnchorEl(null)
    }
  };

  const updateCase = async (option: string): Promise<void> => {
    const data = { [name]: option }
    setIsDialogButtonDisabled(true)
    try {
      const response = await updateEapxCase(fileNo, data)
      if (setCaseStatus) {
        setCaseStatus(state)
      }
      if (setSuccessMessage) {
        setSuccessMessage(response.data.message);
      }
      setAnchorEl(null);
      setIsDialogButtonDisabled(false)
      setOpenDialogBox(false)
    } catch (error) {
      if (caseStatus && name === "eapx_file_status") {
        stateChange(caseStatus)
      } else {
        stateChange("")
      }
      stateChange("")
      setIsDialogButtonDisabled(false)
      globalErrorHandler(error)
      setAnchorEl(null);
    }
  }

  const handleSave = async (state: string): Promise<void> => {
    if (name === "eapx_file_status" && state === "provider_accepted_case") {
      const result = isProviderApproved(additionalProviders, activeCase)
      if (!result) {
        if (showPopUp) {
          setOpenDialogBox(true)
        }
      } else {
        updateCase(state)
      }
    } else {
      updateCase(state)
    }
  };

  const handleMenuItemClick = (value: any) => {
    if (value.full_name) {
      setValueToSend(value.id)
      stateChange(value.full_name)
    } else {
      if (value.label) {
        setValueToSend(value.value)
      } else {
        setValueToSend(value)
      }
      stateChange(value.label ? value.label : value);
    }
  };

  return (
    <Box>
      <Button
        aria-controls="assigned-specialist-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          textAlign: 'left',
          backgroundColor: 'white',
          color: '#000000',
        }}
        variant='contained'
        color='info'
        endIcon={<ArrowDropDownIcon />}
      >
        {state || name}
      </Button>
      <Menu
        id="assigned-specialist-menu"
        anchorEl={anchorEl}
        open={isMenuDisabled ? false : Boolean(anchorEl)}
        onClose={handleClose}
        defaultValue={state}
        MenuListProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : '100',
            paddingBottom: '0px'
          }
        }}
      >
        {
          options && Object.values(options).map((item: SideBarTabItemType1 | SideBarTabItemType2, index: number) => {
            const isEapxFileStatus = name === "eapx_file_status" && casesTypeName === PRTUserCasesType.MY_CASES.label && index === 3;
            const disableFirstThreeForEapxFileStatus = name === "eapx_file_status" && casesTypeName === PRTUserCasesType.CLOSED.label && index < 3;
            return (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(item)}
                disabled={isEapxFileStatus || disableFirstThreeForEapxFileStatus}
                style={"label" in item ? state === item.label ? { fontWeight: "600" } : {} : state === item.full_name ? { fontWeight: "600" } : {}}>
                {"label" in item ? item.label : item.full_name}
                {state === ("label" in item ? item.label : item.full_name) && (
                  <img src={TickButton} alt="checked" style={{ marginLeft: "auto" }} />
                )}
              </MenuItem>
            )
          })
        }
        <Box>
          {
            showButtons && <div className={'button-area'}>
              <button onClick={handleCancelButtonPressed} className='cancel-btn'>
                {"Cancel"}
              </button>
              <button onClick={() => handleSave(valueToSend)} className={isDialogButtonDisabled ? 'processing-btn' : 'save-btn'} disabled={isDialogButtonDisabled}>
                {"Save"}
              </button>
            </div>
          }
        </Box>
        <ConfirmationDialogBox
          title={caseStatusChangeDialog.title}
          content={caseStatusChangeDialog.content}
          onConfirm={() => updateCase(valueToSend)}
          handleClose={handleCancelButtonPressed}
          confirmLabel={"Confirm"}
          openDialogBox={openDialogBox}
          buttonDisabled={isDialogButtonDisabled} />
      </Menu>
    </Box>
  );
};

export default DropdownWithConfirmationBar;
