import React, { useState, useEffect } from "react";
import { headerText, common, home } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  getClassNameForService,
  getClassNameForServiceVisibility,
} from "../../../utils/helperFunctions";
import {
  service1,
  service2,
  service3,
  service4,
} from "../../../utils/constants";
import "./ServiceComponent.scss";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import useGlobalState from "../../../context/useGlobalState";

interface ServiceCardsProps {
  handleButtons: (buttonName?: string) => void;
  serviceType: any;
  activeTickets?: number;
  isLandingPage: boolean;
}

const ServiceCards: React.FC<ServiceCardsProps> = ({
  serviceType,
  handleButtons,
  activeTickets,
  isLandingPage,
}) => {
  let disableButton = activeTickets && activeTickets > 0;
  const { menu } = useGlobalState();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("");
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.6 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.6 });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.6 });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (inView1) setActiveSection("service1");
    else if (inView2) setActiveSection("service2");
    else if (inView3) setActiveSection("service3");
    else if (inView4) setActiveSection("service4");
  }, [inView1, inView2, inView3, inView4]);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = document.querySelector('.header')?.clientHeight || 0; 
      const extraSpace = 15; 
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - headerOffset - extraSpace;

      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="service-component">
      {menu && (
        <div className="menu-list">
          <span
            className={getClassNameForService(
              serviceType,
              activeSection,
              "service1",
              menu
            )}
            onClick={() => scrollToSection(`service1`)}
          >
            {headerText.MENUITEM1}
          </span>
          <span
            className={getClassNameForService(
              serviceType,
              activeSection,
              "service2",
              menu
            )}
            onClick={() => scrollToSection(`service2`)}
          >
            {headerText.MENUITEM2}
          </span>
          <span
            className={getClassNameForService(
              serviceType,
              activeSection,
              "service3",
              menu
            )}
            onClick={() => scrollToSection(`service3`)}
          >
            {headerText.MENUITEM3}
          </span>
          <span
            className={getClassNameForService(
              serviceType,
              activeSection,
              "service4",
              menu
            )}
            onClick={() => scrollToSection(`service4`)}
          >
            {headerText.MENUITEM4}
          </span>
          <span className={"menu-items"} onClick={() => navigate("/login")}>
            {headerText.LOGIN}
          </span>
        </div>
      )}
      {isLandingPage && serviceType && (
        <div className="sidebar">
          <div className="side-menu">
            <span
              className={getClassNameForService(
                serviceType,
                activeSection,
                "service1",
                menu
              )}
              onClick={() => scrollToSection(`service1`)}
            >
              {headerText.MENUITEM1}
            </span>
            <span
              className={getClassNameForService(
                serviceType,
                activeSection,
                "service2",
                menu
              )}
              onClick={() => scrollToSection(`service2`)}
            >
              {headerText.MENUITEM2}
            </span>
            <span
              className={getClassNameForService(
                serviceType,
                activeSection,
                "service3",
                menu
              )}
              onClick={() => scrollToSection(`service3`)}
            >
              {headerText.MENUITEM3}
            </span>
            <span
              className={getClassNameForService(
                serviceType,
                activeSection,
                "service4",
                menu
              )}
              onClick={() => scrollToSection(`service4`)}
            >
              {headerText.MENUITEM4}
            </span>
          </div>
        </div>
      )}
      <div className="cards-conatiner">
        <span className="topic-title">Access Support</span>
        {serviceType?.length !== 0 ? (
          <div className="container-space">
            <div
              className={getClassNameForServiceVisibility(
                serviceType,
                common.TEXT_COACHING
              )}
            >
              <div className="service-card-box" ref={ref1} id="service1">
                <div>
                  <img src={`${EnvironmentConfig.imageBaseURL}text-coach.png`} alt="text-coaching" className="service-card-box-img" />
                </div>
                <div className="service-card">
                  <span>{service1.title}</span>
                  <p>{service1.summary}</p>
                  <button
                    className={
                      disableButton
                        ? "large-green-button-disabled"
                        : "large-green-button"
                    }
                    name={common.TEXT_COACHING}
                    onClick={() => handleButtons(common.TEXT_COACHING)}
                    disabled={!!disableButton}
                  >
                    {service1.buttonText}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={getClassNameForServiceVisibility(
                serviceType,
                common.SELF_SCHEDULING
              )}
            >
              <div className="service-card-box" ref={ref2} id="service2">
                <img src={`${EnvironmentConfig.imageBaseURL}short-term.png`} alt="self-scheduling" className="service-card-box-img" />
                <div className="service-card">
                  <span>{service2.title}</span>
                  <p>{service2.summary}</p>
                  <button
                    className="large-green-button"
                    name={common.SELF_SCHEDULING}
                    onClick={() => handleButtons(common.SELF_SCHEDULING)}
                  >
                    {service2.buttonText}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={getClassNameForServiceVisibility(
                serviceType,
                [common.TEXT_COACHING, common.SELF_SCHEDULING],
                true
              )}
            >
              <div className="service-card-box" ref={ref3} id="service3">
                <img src={`${EnvironmentConfig.imageBaseURL}worklife.png`} alt="work-life" className="service-card-box-img" />
                <div className="service-card">
                  <span>{service3.title}</span>
                  <p>{service3.summary}</p>
                  <button
                    className="large-green-button"
                    name={common.WORKLIFE_REQUEST}
                    onClick={() => handleButtons(common.WORKLIFE_REQUEST)}
                  >
                    {service3.buttonText}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={getClassNameForServiceVisibility(
                serviceType,
                common.TEXT_COACHING
              )}
            >
              <div className="service-card-box" ref={ref4} id="service4">
                <img src={`${EnvironmentConfig.imageBaseURL}get-ans.png`} alt="get-answer" className="service-card-box-img" />
                <div className="service-card">
                  <span>{service4.title}</span>
                  <p>{service4.summary}</p>
                  <button
                    className={
                      disableButton
                        ? "large-green-button-disabled"
                        : "large-green-button"
                    }
                    name={common.GET_ANSWERS}
                    onClick={() => handleButtons(common.GET_ANSWERS)}
                    disabled={!!disableButton}
                  >
                    {service4.buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span className="service-div">{home.NO_SERVICE}</span>
        )}
      </div>
    </div>
  );
};

export default ServiceCards;
