import React from 'react'
import './DropDownMenu.scss'

interface DropdownProps {
    question: string;
    id: number;
    options: [];
    value: any,
    isRequired: boolean;
    handleInputChange?: (id: number, value: string, required: boolean, question: string) => void;
}

const DropDownMenu: React.FC<DropdownProps> = ({ question, id, options, handleInputChange, isRequired }) => {
    return (
        <div className='dropdown-menu-component' key={id}>
            <span className='input-title'>{question}</span>
            <select
                className="dropdown-menu"
                onChange={(e) => handleInputChange?.(id, e.target.value, isRequired, question)}
            >
                <option defaultValue="" disabled selected>Choose an option</option>
                {
                    options.map((item, index) => (
                        <option defaultValue={item} key={"option" + index}>{item}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default DropDownMenu