import React, { useEffect, useRef, useState } from 'react'
import EAPCaseSideBar from '../../common/SideTabs/eap-cases-sidebar/EAPCaseSideBar'
import Loader from '../../common/Loader/Loader'
import { PRTManagerCaseType } from '../../../utils/constants'
import { EAPXCaseList } from '../../../utils/ApiClient'
import { Provider } from '../prt-dashboard/PRTDashboardHome'
import CaseDetails from '../prt-dashboard/CaseDetails'
import AdditionalProviders from '../prt-dashboard/AdditionalProviders'
import '../prt-dashboard/PRTDashboardHome.scss'
import NoteSection from '../prt-dashboard/NoteSection'
import { PRTCaseDetails } from '../../../interfaces/PRTCaseDetails'
import useGlobalState from '../../../context/useGlobalState'

const PRTManagerDashboard = () => {
    const [activeCase, setActiveCase] = useState<any>("")
    const [cases, setCases] = useState<any[]>([])
    const [caseStatus, setCaseStatus] = useState<string>("")
    const [activeCaseInfo, setActiveCaseInfo] = useState<PRTCaseDetails>()
    const [casesTypeName, setCasesTypeName] = useState<string>(PRTManagerCaseType.ALL_CASES.label)
    const [caseLoading, setCaseLoading] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [additionalProviders, setAdditionalProviders] = useState<{ [caseId: string]: Provider[] }>({});
    const [noData, setNoData] = useState(false)
    const [specialist, setSpecialist] = useState("")
    const [fileNo, setFileNo] = useState<number>(0)
    const [isUnassign, setIsUnassign] = useState<boolean>(false)
    const { globalErrorHandler } = useGlobalState()
    const [notes, setNotes] = useState<string>("")
    const effectRef = useRef(false)

    const caseData = (data: any) => {
        if (data !== null || data !== undefined) {
            setFileNo(data?.file_number)
            setSpecialist(data?.assigned_specialist)
            setCaseStatus(data?.eapx_file_status_display)
            setNotes(data?.notes)
        }
    }

    const getEapxCases = async (caseType : string): Promise<void> => {
        try {
            const response = await EAPXCaseList(caseType)
            if (!response.data.data.cases.length && response.data.data.first_case_detail === null) {
                setNoData(true)
                setCases([])
            }
            else {
                setCases(response.data.data.cases);
                setActiveCase(response.data.data.first_case_detail.id)
                setActiveCaseInfo(response.data.data.first_case_detail)
                setFileNo(response.data.data.first_case_detail.file_number)
                caseData(response.data.data.first_case_detail)
                setNoData(false)
            }
            setLoading(false)
            setCaseLoading(false)
            setIsUnassign(false)
        } catch (error) {
            globalErrorHandler(error)
            setLoading(false)
            setCaseLoading(false)
        }
    }

    useEffect(() => {
        if (effectRef.current === false) {
            getEapxCases( PRTManagerCaseType.ALL_CASES.value)
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (activeCase && activeCaseInfo) {
            setAdditionalProviders((prevState: any) => ({
                ...prevState,
                [activeCase]: activeCaseInfo.provider_outreach.map((value: any) => ({ ...value, status: "Save" }))
            }));
        }
    }, [activeCaseInfo]);

    useEffect(() => {
        if (cases?.length) {
            caseData(activeCaseInfo)
        }
    }, [activeCase, activeCaseInfo, cases, casesTypeName])

    useEffect(() => {
        if (isUnassign) {
            setLoading(true)
            getEapxCases( PRTManagerCaseType.ALL_CASES.value)
        }
    }, [isUnassign])

    return (
        <div className='eap-cases-page'>
            {
                loading ? <div className='loader-component-section'> <Loader /></div> :
                    <div className='case-info-section'>
                        <EAPCaseSideBar
                            onCaseTypeTabChange={getEapxCases}
                            tabs={PRTManagerCaseType}
                            cases={cases}
                            setLoading={setLoading}
                            setActiveCaseInfo={setActiveCaseInfo}
                            casesTypeName={casesTypeName}
                            setCasesTypeName={setCasesTypeName}
                            activeCase={activeCase}
                            setActiveCase={setActiveCase}
                            setCaseLoading={setCaseLoading}
                            caseStatus={caseStatus}
                            caseLoading={caseLoading}
                            setFileNo={setFileNo}
                            setNoData={setNoData} />
                        {
                            caseLoading ? <div className='case-content'>
                                <Loader />
                            </div> : <React.Fragment>
                                {
                                    noData ? <span className='no-data-section'>{"No cases found"}</span> :
                                        <div className='case-content'>
                                            <CaseDetails
                                                activeCaseInfo={activeCaseInfo}
                                                caseStatus={caseStatus}
                                                caseLoading={caseLoading}
                                                setCaseLoading={setCaseLoading}
                                                setCaseStatus={setCaseStatus}
                                                activeCase={activeCase}
                                                additionalProviders={additionalProviders}
                                                setSpecialist={setSpecialist}
                                                specialist={specialist}
                                                casesTypeName={casesTypeName}
                                                setCasesTypeName={setCasesTypeName}
                                                setIsUnassign={setIsUnassign} />
                                            <div className='case-sub-section'>
                                                {additionalProviders[activeCase]?.map((provider: any, index) => (
                                                    <AdditionalProviders
                                                        key={index + 2}
                                                        providerNo={index}
                                                        provider={provider}
                                                        activeCase={activeCase}
                                                        setAdditionalProviders={setAdditionalProviders}
                                                        additionalProviders={additionalProviders}
                                                        caseStatus={caseStatus}
                                                        setCasesTypeName={setCasesTypeName}
                                                        setNoData={setNoData}
                                                        setLoading={setLoading}
                                                        setCases={setCases}
                                                        setActiveCase={setActiveCase}
                                                        setActiveCaseInfo={setActiveCaseInfo}
                                                        casesTypeName={casesTypeName} />
                                                ))}
                                            </div>
                                            <NoteSection
                                                notes={notes}
                                                setNotes={setNotes}
                                                fileNo={activeCaseInfo?.file_number} />
                                        </div>
                                }
                            </React.Fragment>
                        }
                    </div>
            }
        </div>
    )
}

export default PRTManagerDashboard