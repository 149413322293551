import React, { useEffect, useState } from 'react'
import './CourseSection.scss'
import DropDownIcon from '../../../component/assests/icons/black-down-arrow.png'
import UpArrowIcon from '../../../component/assests/icons/black-up-arrow.png'
import { ApiLesson, Sections } from '../../../interfaces/MemberPortal'
import CourseListItem from './CourseListItem'
import { Link, useNavigate } from 'react-router-dom'
import { updateModuleProgress } from '../../../utils/ApiClient'
import { ModuleStatus } from '../../../utils/member-portal/constant'
import useGlobalState from '../../../context/useGlobalState'

interface Props {
    cbtCourseList: Sections[];
    moduleId: number;
    startButtonClick: () => void;
    isStarted: boolean;
}

const CourseSection: React.FC<Props> = ({ cbtCourseList, moduleId, startButtonClick, isStarted }) => {
    const [collapsedSections, setCollapsedSections] = useState<Record<number, boolean>>({});
    const { globalErrorHandler } = useGlobalState()

    useEffect(() => {
        if (cbtCourseList.length > 0) {
            setCollapsedSections({ 0: true });
        }
    }, [cbtCourseList]);

    const navigate = useNavigate()
    const toggleSectionCollapse = (index: number) => {
        setCollapsedSections((prevCollapsed) => ({
            ...prevCollapsed,
            [index]: !prevCollapsed[index],
        }));
    }

    const handleModuleProgress = async (lessonID: number): Promise<void> => {
        try {
            await updateModuleProgress(moduleId ?? null, ModuleStatus.inProgress)
            navigate(`/exercise/${moduleId}`, { state: { lessonID } });
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    return (
        <div>
            {
                cbtCourseList.map((item: Sections, index: number) => {
                    return <div className='course-section-component'
                        key={index + "course"}>
                        <div className='section-title-row' onClick={() => toggleSectionCollapse(index)}>
                            <div className='section-icon-box'>
                                <img src={collapsedSections[index] ? UpArrowIcon : DropDownIcon} alt='collased' />
                                <span className='section-title'>{item.name}</span>
                            </div>
                            {
                                index === 0 && <>
                                    {
                                        isStarted ? <Link to={`/exercise/${moduleId}`} className='no-underline'><button className="green-border-button">RESUME</button></Link> : <button className="green-rounded-btn" onClick={startButtonClick}>Start</button>
                                    }
                                </>
                            }
                        </div>
                        <div className='course-list'>
                            {
                                collapsedSections[index] && item.lessons.lessons.map((item: ApiLesson, index: number) => {
                                    return <div key={index + "item"} onClick={() => handleModuleProgress(item.id)}>
                                        <CourseListItem item={item} />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div >
    )
}

export default CourseSection