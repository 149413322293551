import {useEffect} from 'react'
import { WorkLifeReqForm, common, storageKeys } from "../../../../utils/constants"
import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useState } from "react"
import { validate } from "./workLifeFormValidation";
import { useNavigate } from "react-router-dom";
import './WorkLifeForm.scss'
import '../../../../styles/formStyles.scss'

const WorkLifeForm = () => {
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        dob: "",
        company: "",
        member_type: "",
        gender: "",
        marital_status: "",
        phone: "",
        email: "",
        street_address: "",
        apt_floor: "",
        city: "",
        state: "",
        zipcode: "",
        reference_question: ""
    })
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);

    const handleInput = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ): void => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDropdownChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        const dropdownName = event.target.name as string;
        setFormData({ ...formData, [dropdownName]: selectedValue });
    };

    const handleSubmit = async (): Promise<void> => {
        const result: any = validate(formData);
        if (result.length !== 0) {
            setErrorMessage(result[0]);
            setError(true);
        } else {
            setErrorMessage("");
            setError(false);
            localStorage.setItem(storageKeys.MemberInfo, JSON.stringify(formData))
            navigate('/worklife-categories')
        }
    }

    return (
        <div className="worklife-req-form page-content">
            <span className="subtitle">{WorkLifeReqForm.TITLE}</span>
            <span className="sub-title">{WorkLifeReqForm.SUBTITLE}</span>
            <form className="form-container responsive-form">
                <div className="form-grid">
                    <div className="input-div">
                        <label>{common.FIRST_NAME}</label>
                        <input
                            name="first_name" type="text" value={formData.first_name} onChange={handleInput} />
                    </div>
                    <div className="input-div">
                        <label>{common.LAST_NAME}</label>
                        <input
                            name="last_name" type="text" value={formData.last_name} onChange={handleInput} />
                    </div>
                </div>
                <div className="input-div">
                    <label>{common.DATE_OF_BIRTH}</label>
                    <input
                        name="dob" type="date" value={formData.dob} onChange={handleInput} />
                </div>
                <div className="form-grid">
                    <div className="input-div">
                        <label>{common.COMPANY}</label>
                        <input
                            name="company" type="text" value={formData.company} onChange={handleInput} />
                    </div>
                    <div className="input-div">
                        <label>{common.MEMBER_TYPE}</label>
                        <Select
                            id="member_type"
                            name="member_type"
                            className="input-div-select"
                            value={formData.member_type}
                            onChange={(
                                event: SelectChangeEvent<string>,
                                child: React.ReactNode
                            ) => handleDropdownChange(event)} >
                            {
                                WorkLifeReqForm.CLIENT_TYPE.map((item, index) => {
                                    return <MenuItem key={index} value={item}>{item}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="input-div">
                        <label>{common.GENDER}</label>
                        <Select
                            name="gender"
                            id="gender"
                            value={formData.gender}
                            className="input-div-select"
                            onChange={(
                                event: SelectChangeEvent<string>,
                                child: React.ReactNode
                            ) => handleDropdownChange(event)} >
                            {
                                WorkLifeReqForm.GENDER.map((item, index) => {
                                    return <MenuItem key={index} value={item}>{item}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
                    <div className="input-div">
                        <label>{common.MARITAL_STATUS}</label>
                        <Select
                            id="marital_status"
                            name="marital_status"
                            className="input-div-select"
                            onChange={(
                                event: SelectChangeEvent<string>,
                                child: React.ReactNode
                            ) => handleDropdownChange(event)} >
                            {
                                WorkLifeReqForm.MARITAL_STATUS.map((item, index) => {
                                    return <MenuItem key={index} value={item}>{item}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="input-div">
                        <label>{common.PHONE}</label>
                        <input
                            name="phone" type="number" value={formData.phone} onChange={handleInput} />
                    </div>
                    <div className="input-div">
                        <label>{common.EMAIL}</label>
                        <input
                            name="email" value={formData.email} onChange={handleInput} />
                    </div>
                </div>
                <div className="form-grid">
                    <div className="input-div">
                        <label>{common.STREET_ADDRESS}</label>
                        <input
                            name="street_address" value={formData.street_address} onChange={handleInput} />
                    </div>
                    <div className="input-div">
                        <label>{common.APT_FLOOR}<span className="optional-text">&nbsp;{"(optional)"}</span></label>
                        <input
                            name="apt_floor" value={formData.apt_floor} onChange={handleInput} />
                    </div>
                </div>
                <div className="form-flex">
                    <div className="input-div">
                        <label>{common.CITY}</label>
                        <input name="city" value={formData.city} onChange={handleInput} />
                    </div>
                    <div className="input-div">
                        <label>{common.STATE}</label>
                        <input name="state" value={formData.state} onChange={handleInput} />
                    </div>
                    <div className="input-div">
                        <label>{common.ZIPCODE}</label>
                        <input name="zipcode" type="number" value={formData.zipcode} onChange={handleInput} />
                    </div>
                </div>
                <div className="input-div">
                    <label>{common.REFERENCE_QUE}</label>
                    <Select
                        id="reference_question"
                        name="reference_question"
                        className="input-div-select"
                        value={formData.reference_question}
                        onChange={(
                            event: SelectChangeEvent<string>,
                            child: React.ReactNode
                        ) => handleDropdownChange(event)} >
                        {
                            WorkLifeReqForm.REFERNCE_QUE.map((item, index) => {
                                return <MenuItem key={index} value={item}>{item}</MenuItem>
                            })
                        }
                    </Select>
                </div>
            </form>
            {error && <div className="error">{errorMessage}</div>}
            <button className="question-button-yes" onClick={handleSubmit}>{common.SUBMIT}</button>
        </div>
    )
}

export default WorkLifeForm