import React from 'react'
import './ModuleSidebar.scss'
import CourseModule from './course-module/CourseModule'
import { Sections } from '../../../../interfaces/MemberPortal'
import BackToModule from '../../../common/BackToModule/BackToModule'
import useGlobalState from '../../../../context/useGlobalState'

interface SidebarProps {
    id: number
}

const ModuleSidebar: React.FC<SidebarProps> = ({ id }) => {
    const { cbtModuleSections, lessonId} = useGlobalState();

    return (
        <div className='module-sidebar-component'>
            <BackToModule name='Back to module' path={`/section/${id}`} />
            <div className='modules-list'>
                {
                    cbtModuleSections.map((item: Sections, index: number) => {
                        return <CourseModule list={item} key={'module' + index} lessonId={lessonId ?? 0} />
                    })
                }
            </div>
        </div >
    )
}

export default React.memo(ModuleSidebar)