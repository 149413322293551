import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import ContextMedia from "./ContentMedia";

const Index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<ContextMedia />} />
  );
};

export default Index;
