import LeftArrowIcon from '../../assests/icons/left-arrow.png'
import { Link } from 'react-router-dom'
import './BackToModule.scss';

interface BackToModuleProps {
    name: string;
    path: string;
}

const BackToModule: React.FC<BackToModuleProps> = ({ name, path }) => {
    return (
        <Link to={`${path}`} className='no-underline'>
            <div className='back-module-component'>
                <img src={LeftArrowIcon} alt='left-arrow' className='navigation-icon' />
                <span className='navigation-title'>{name}</span>
            </div>
        </Link>
    )
}

export default BackToModule