const ALLOWED_APP_ENVIRONMENTS = [
  "production",
  "demo",
  "development",
  "test",
];

const TEST_OIDS = [
  {
    name: "CCA (Test) /  Both",
    oid: "bdc8300a-60f5-47ca-8505-9f383eb52cc5",
  },
  {
    name: "Whole Foods /  Self Scheduling",
    oid: "12f602b9-cd1f-4f04-940a-4d892737d5fb",
  },
  {
    name: "IMA Financial Group  / Text Coaching",
    oid: "d83b519e-35ab-4cae-816b-f9cf2edb6ca9",
  },
  {
    name: "Journey / None",
    oid: "d46ff88e-8b23-40f3-b4d8-ab70f549f8bd",
  },
  {
    name: "Henry Schein",
    oid: "039e3942-1f0b-48f7-9242-54c320d20266",
  },
];

/**
 * The only environment needed is REACT_APP_ENVIRONMENT, which can be one of the values specified in ALLOWED_APP_ENVIRONMENTS
 */
class EnvironmentConfig {
  // Configuration Variables
  environment: string;
  ccaApiRoot: string;
  sendbirdAppId: string;
  selfSchedulingRedirectUrl: string;
  eapxOnlineIntakeFormUrl: string;
  enableTestPage: boolean;
  companyNameField: string;
  show_gad7_phq9: boolean;
  sentryUrl: string;
  testOids: Array<{ name: string; oid: string }>;
  supportEmail: string;
  showWorkLifeRequest: boolean;
  showCoversationSideTabs: boolean;
  algoliaAPIKey:string;
  algoliaAppID:string;
  imageBaseURL:string;

  constructor() {
    const selectedEnvironment = this.getEnvVar("REACT_APP_ENVIRONMENT");

    if (ALLOWED_APP_ENVIRONMENTS.includes(selectedEnvironment)) {
      this.environment = selectedEnvironment;
    } else {
      throw new Error(
        `Invalid environment value found: ${selectedEnvironment} expected: ${ALLOWED_APP_ENVIRONMENTS}`
      );
    }

    this.companyNameField = this.getValueForEnvironment("field", {
      production: "field:14501719",
      demo: "field:14501719",
      development: "field:14501719",
      test: "field:14501719",
    });

    this.ccaApiRoot = this.getValueForEnvironment("apiRoot", {
      production: "https://api.ccaplatform.com",
      demo: "https://members-api.ccaplatformdemo.com",
      development: "http://localhost:8000",
      test: "http://localhost:8000",
    });

    this.sendbirdAppId = this.getValueForEnvironment("sendbirdAppId", {
      production: "79E36A13-45DD-488F-A554-36988422BC4F",
      demo: "6A411568-A143-430D-9CE0-88CDDFB8EB83",
      development: "6A411568-A143-430D-9CE0-88CDDFB8EB83",
      test: "6A411568-A143-430D-9CE0-88CDDFB8EB83",
    });

    this.selfSchedulingRedirectUrl = this.getValueForEnvironment(
      "selfSchedulingRedirectUrl",
      {
        production: "https://CCAEAPIntake.as.me/",
        demo: "https://ccaeapintake.as.me/CCAPlatformCalendarTest",
        development: "https://ccaeapintake.as.me/CCAPlatformCalendarTest",
        test: "https://ccaeapintake.as.me/CCAPlatformCalendarTest",
      }
    );

    this.enableTestPage = this.getValueForEnvironment("enableTestPage", {
      production: true,
      demo: true,
      development: true,
      test: true,
    });

    this.show_gad7_phq9 = this.getValueForEnvironment("show_gad7_phq9", {
      production: false,
      demo: true,
      development: true,
      test: true,
    });

    this.sentryUrl = this.getValueForEnvironment("sentryUrl", {
      production:
        "https://770c27e906ed6d8c6987a63ca150ff7a@o4506692491608064.ingest.us.sentry.io/4507031469228032",
      demo:
        "https://87a651b64e291c96f7b2892ab25c768f@us.sentry.io/4506698465083392",
      development:
        "https://87a651b64e291c96f7b2892ab25c768f@us.sentry.io/4506698465083392",
      test: "https://87a651b64e291c96f7b2892ab25c768f@us.sentry.io/4506698465083392",
    });

    this.testOids = this.getValueForEnvironment("testOids", {
      production: [TEST_OIDS[0]],
      demo: TEST_OIDS,
      development: TEST_OIDS,
      test: TEST_OIDS,
    });

    this.eapxOnlineIntakeFormUrl = this.getValueForEnvironment("", {
      production: "https://cca.eapintake.com/Pages/cca/cca",
      demo: "https://www.ccainc.com",
      development: "https://www.ccainc.com",
      test: "https://www.ccainc.com",
    });

    this.supportEmail = this.getValueForEnvironment("supportEmail", {
      production: "support@mail.ccaplatform.com",
      demo: "support+staging@mail.ccaplatform.com",
      development: "support+stagin@mail.ccaplatform.com",
      test: "support+stagin@mail.ccaplatform.com",
    });

    this.showWorkLifeRequest = this.getValueForEnvironment("showWorkLifeRequest", {
      production: false,
      demo: true,
      development: true,
      test: false,
    });

    this.showCoversationSideTabs = this.getValueForEnvironment("showCoversationSideTabs", {
      production: false,
      demo: false,
      development: false,
      test: false,
    });

    this.algoliaAppID = this.getValueForEnvironment("algoliaAppID", {
      production: "D9W4WEQKHU",
      demo: "D9W4WEQKHU",
      development: "D9W4WEQKHU",
      test: "D9W4WEQKHU",
    });

    this.algoliaAPIKey = this.getValueForEnvironment("algoliaAPIKey", {
      production: '859b4b417981742260e1c307ea68138b',
      demo: '859b4b417981742260e1c307ea68138b',
      development: '859b4b417981742260e1c307ea68138b',
      test: '859b4b417981742260e1c307ea68138b',
    });

    this.imageBaseURL = this.getValueForEnvironment("imageBaseURL", {
      production: 'https://storage.googleapis.com/members_portal_static_images/',
      demo: 'https://storage.googleapis.com/members_portal_static_images/',
      development: 'https://storage.googleapis.com/members_portal_static_images/',
      test: 'https://storage.googleapis.com/members_portal_static_images/',
    });

  }

  private getEnvVar(varName: string): string {
    return localStorage.getItem(varName) || process.env[varName] || "";
  }

  private getValueForEnvironment<R>(
    valueName: string,
    options: { [key: string]: R }
  ): R {
    const selectedValue = options[this.environment];

    if (!selectedValue && selectedValue !== false) {
      throw new Error(
        `Invalid ${valueName} value found: '${selectedValue}' for '${this.environment}' environment. Expected a string.`
      );
    } else {
      return selectedValue;
    }
  }
}

export default new EnvironmentConfig();
