import React, { useState, useEffect } from 'react'
import './IncrementDecrementButton.scss'
import { AnswerType } from '../JournalEntry/JournalEntry';

interface Props {
  id: number;
  title: string;
  handleInputChange: (id: number, answer: number, required: boolean, question: string) => void;
  answer: AnswerType;
  required: boolean
}

const IncrementDecrementButton: React.FC<Props> = ({ title, required, id, handleInputChange, answer }) => {
  const [counter, setCounter] = useState<number>(0)

  useEffect(() => {
    setCounter(answer?.answer as number || 0)
  }, [answer])

  return (
    <div className='increment-decrement-component'>
      <span className='input-title'>{title}</span>
      <div className='combo-button'>
        <button onClick={() => {
          setCounter(counter > 0 ? counter - 1 : 0);
          handleInputChange(id, counter > 0 ? counter - 1 : 0, required, title)
        }} className="button decrement">-</button>
        <span className="count">{counter}</span>
        <button onClick={() => {
          setCounter(counter > 0 ? counter - 1 : 0);
          handleInputChange(id, counter < 10 ? counter + 1 : counter, required, title)
        }} className="button increment">+</button>
      </div>
    </div>
  )
}

export default IncrementDecrementButton