import './HeroSection.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const HeroSectionHome = () => {
    const settings = {
        dots: true,
        nav: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
    };
    return (
        <div className='hero-section-component'>
            <Slider {...settings}>
                <div className='hero-img-one'>
                    <div className="hero-slider-conatiner">
                        <div className='hero-image'>
                            <span className='hero-first-title'>Essential earthquake preparedness tips and lifesaving resources.</span>
                            <div className='button-box'>
                                <button className='hero-button'>READ MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hero-img-two'>
                    <div className='center-content'>
                        <h3 className='hero-title'>If you are in need of immediate help please call our 24/7/365 access line.</h3>
                        <h2 className='decorated-text'>800-833-8707</h2>
                    </div>
                </div>
                <div className='hero-img-three'>
                    <div className="hero-slider-conatiner">
                        <div className='hero-image-third'>
                            <h2 className='hero-third-title'>Mental Health Resource</h2>
                            <div className='button-box'>
                                <Link to={'/content-media/1'} className='no-underline'><button className='hero-button'>READ MORE</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default HeroSectionHome