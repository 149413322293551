import './PopularTopics.scss'
import BookIcon from '../component/assests/icons/book.png'
import SlackIcon from '../component/assests/icons/slack.png'
import RightArraow from '../component/assests/icons/right-side-arrow.png'
import TopicCard from '../component/common/Cards/TopicCard'
import { Link } from 'react-router-dom'
import EnvironmentConfig from '../component/config/EnvironmentConfig'

const PopularTopics = () => {

    const TopicOne = [
        { title: "Intro to CBT", src: SlackIcon, path: "/module/1" },
        { title: "About Emotional Well-Being", src: BookIcon, path: "/content-media/30" },
        { title: "Mental Health Resource", src: BookIcon, path: "/content-media/1" },
        { title: "Coping With Grief", src: BookIcon, path: "/content-media/29" }];
    const TopicTwo = [
        { title: "Creating Healthy Habits", src: BookIcon, path: "/content-media/23" },
        { title: "About Sleep", src: BookIcon, path: "/content-media/24"},
        { title: "Step It Up!", src: BookIcon, path: "/content-media/22" },
        { title: "Drug Use and Addiction", src: BookIcon, path: "/content-media/18" }];
    const TopicThree = [
        { title: "Crispy Oven-Fried Chicken", src: BookIcon, path: "/content-media/4" },
        { title: "Baked Tilapia With Tomatoes", src: BookIcon, path: "/content-media/7" },
        { title: "Asian-Style Chicken Wraps", src: BookIcon, path: "/content-media/9" },
        { title: "Zesty Tomato Soup", src: BookIcon, path: "/content-media/10" }];

    return (
        <div className='popular-topics-component'>
            <div className='titlebar'>
                <div className='topic-title'>Popular topics</div>
                <Link to={"/explore-topics"} className="no-underline">
                    <div className='more-topics'>
                        <div className='arrow-text'>Explore more topics</div>
                        <img src={RightArraow} alt='right-arraow' />
                    </div>
                </Link>
            </div>
            <TopicCard
                title='Mental Health'
                path="/module/1"
                image={`${EnvironmentConfig.imageBaseURL}topic1.png`}
                menuItems={TopicOne} />
            <TopicCard
                title='General Health'
                path="/module/7"
                image={`${EnvironmentConfig.imageBaseURL}general_health.png`}
                menuItems={TopicTwo} />
            <TopicCard
                title='Nutrition'
                path="/module/10"
                image={`${EnvironmentConfig.imageBaseURL}nutrition.png`}
                menuItems={TopicThree} />
        </div>
    )
}

export default PopularTopics