import { useState } from "react";
import "./ServicesCheck.scss";
import {
  emailCheck,
  getOrganizationDetail,
  serviceCheck,
} from "../../../utils/ApiClient";
import useGlobalState from "../../../context/useGlobalState"

const EmailCheck = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false);
  const [eapxDisabled, setEAPXDisabled] = useState<boolean>(false);
  const [sendBirdDisabled, setSendBirdDisabled] = useState<boolean>(false);
  const { globalErrorHandler } = useGlobalState();
  const OID = "bdc8300a-60f5-47ca-8505-9f383eb52cc5";
  const handleClick = async () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    if (!email || !emailRegex.test(email)) {
      setMessage("Enter valid email address");
      setShowMessage(true);
    } else {
      try {
        setEmailDisabled(true);
        const response = await emailCheck(email);
        setMessage(response?.data.message);
        setShowMessage(true);
        setEmailDisabled(false);
      } catch (error) {
        globalErrorHandler(error);
        setEmailDisabled(false);
      }
    }
  };
  const handleSystemCheck = async () => {
    setEAPXDisabled(true);
    try {
      const response: any = await getOrganizationDetail(OID);
      setMessage(response?.data.message);
      setShowMessage(true);
      setEAPXDisabled(false);
      setEmail("");
    } catch (error) {
      globalErrorHandler(error);
      setEAPXDisabled(false);
    }
  };
  const handleSendBirdService = async () => {
    setSendBirdDisabled(true);
    try {
      const response = await serviceCheck();
      setMessage(response?.data.message);
      setShowMessage(true);
      setSendBirdDisabled(false);
    } catch (error) {
      globalErrorHandler(error);
      setSendBirdDisabled(false);
    }
  };

  return (
    <div className="services-check-page">
      <span className="subtitle">
        Please enter your email to test email delivery
      </span>
      <div className="signup-form">
        <input
          type="email"
          name="email"
          className="signup-page-email-input"
          value={email}
          placeholder="Email Address"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          onClick={handleClick}
          className={
            emailDisabled ? "large-green-button-disabled" : "large-green-button"
          }
          disabled={emailDisabled}
        >
          Check Email Service
        </button>
      </div>
      <div className="signup-form">
        <span className="subtitle">Check EAPx and SendBird Service</span>
        <div className="service-button">
          <button
            onClick={handleSystemCheck}
            className={
              eapxDisabled
                ? "service-check-button-disable"
                : "service-check-button"
            }
            disabled={eapxDisabled}
          >
            EAPx Service
          </button>
          <button
            onClick={handleSendBirdService}
            className={
              sendBirdDisabled
                ? "service-check-button-disable"
                : "service-check-button"
            }
            disabled={sendBirdDisabled}
          >
            SendBird Service
          </button>
        </div>
      </div>
      {message && showMessage && <span className="message">{message}</span>}
    </div>
  );
};

export default EmailCheck;
