import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { GeneralQuestions, common, EapxParmas, storageKeys } from "../../../utils/constants";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import useGlobalState from "../../../context/useGlobalState"
import { sendDataToEAPX } from "../../../utils/ApiClient";
import CredentialStorage from "../../../utils/CredentialStorage";
import Loader from "../../common/Loader/Loader";
import { getQueryParams } from "../../../utils/helperFunctions";
import './GeneralQuestion.scss'

const GeneralSelfSchedulingQuestions: React.FC = () => {
  const [selectedOption1, setSelectedOption1] = useState<string>("");
  const [selectedOption2, setSelectedOption2] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const { globalErrorHandler } = useGlobalState();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const initiateEAPXProcess = async (): Promise<void> => {
    let params: EapxParmas = CredentialStorage.eapxValues();
    try {
      setDisable(true);
      await sendDataToEAPX(params);
      if (EnvironmentConfig.show_gad7_phq9) {
        navigate("/standardized-survey-one");
      } else {
        const queryParams = getQueryParams()
        window.location.href = `${EnvironmentConfig.selfSchedulingRedirectUrl}${queryParams}`;
      }
    } catch (error: any) {
      globalErrorHandler(error);
      setLoading(false);
      setDisable(false);
    }
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const dropdownName = event.target.name as string;
    setError(false);
    if (dropdownName === "question1") {
      setSelectedOption1(selectedValue);
    } else if (dropdownName === "question2") {
      setSelectedOption2(selectedValue);
    }
  };

  const handleNavigation = () => {
    if (!selectedOption1) {
      setErrorMessage("Please select how you heard about the service.");
      setError(true);
    } else if (!selectedOption2) {
      setErrorMessage("Please select in what area we can be of support.");
      setError(true);
    } else {
      setLoading(true);
      localStorage.setItem(storageKeys.GeneralScheduleQuestion, selectedOption1);
      localStorage.setItem(storageKeys.SelfScheduleQuestion, selectedOption2);
      initiateEAPXProcess();
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div className="general-question-page page-content margin-content general-que-margin">
          <span className="general-question">{GeneralQuestions.QUESTION1}</span>
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="question1"
                value={selectedOption1}
                displayEmpty
                onChange={(
                  event: SelectChangeEvent<string>,
                  child: React.ReactNode
                ) => handleDropdownChange(event)}
                className="select-menu"
              >
                <MenuItem value="" disabled>
                  <em>Select an option</em>
                </MenuItem>
                {GeneralQuestions.OPTION1.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={"que1" + index}
                      style={{ whiteSpace: "normal", background: "white" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <span className="general-question">{GeneralQuestions.QUESTION2}</span>
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <Select
                size="medium"
                name="question2"
                value={selectedOption2}
                displayEmpty
                onChange={(
                  event: SelectChangeEvent<string>,
                  child: React.ReactNode
                ) => handleDropdownChange(event)}
                className="select-menu"
              >
                <MenuItem value="" disabled>
                  <em>Select an option</em>
                </MenuItem>
                {GeneralQuestions.OPTION2.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={"que1" + index}
                      style={{ whiteSpace: "normal" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {error && errorMessage && (
            <span className="error">{errorMessage}</span>
          )}
          <div className="button-box">
            <button
              className={
                disable ? ".disabled-rounded-btn" : "green-rounded-btn"
              }
              onClick={handleNavigation}
              disabled={disable}
            >
              {common.NEXT}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralSelfSchedulingQuestions;
