import React, { useEffect, useState } from "react";
import { Line } from "rc-progress";
import "./ProgressBar.scss";
import { ModuleStatus } from "../../../utils/member-portal/constant";

interface ProgressProps {
  status: string
}

const GreenProgressBar: React.FC<ProgressProps> = ({ status }) => {
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    if (status === ModuleStatus.inProgress) {
      setPercentage(50);
    } else if (status === ModuleStatus.completed) {
      setPercentage(100);
    }
  }, [status]);

  return (
    <Line
      percent={percentage}
      strokeWidth={1}
      strokeColor="#2B7272"
      trailColor="#E5E5E5"
      className="progressbar-component"
    />
  );
};

export default GreenProgressBar;
