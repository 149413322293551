import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { signup } from "../../../utils/constants";
import { register } from "../../../utils/ApiClient";
import { validate } from "../../pages/register/validate";
import { storageKeys } from "../../../utils/constants";
import "./CreateCustomerModal.scss";
import useGlobalState from "../../../context/useGlobalState";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  customerFromModal: (addedUser: boolean) => void;
}

const CreateCustomerModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  customerFromModal,
}) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const { globalErrorHandler } = useGlobalState()
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const organizationId = localStorage.getItem(storageKeys.OrganizationID);
  const organizationName = localStorage.getItem(storageKeys.Company);
  const data = {
    first_name: formData.firstname,
    last_name: formData.lastname,
    email: formData.email,
    organization_id: organizationId,
    organization_name: organizationName,
  };
  const handleSubmit = async () => {
    const hasError = validate(formData);
    if (hasError) {
      setError(hasError);
    } else {
      setDisabled(true);
      try {
        await register(data);
        customerFromModal(true);
        handleClose();
      } catch (error) {
        globalErrorHandler(error);
        handleClose();
      }
    }
  };
  return (
    <div className="create-customer-modal-component">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="form-div">
            <div className="input-div" style={{ marginTop: "20px" }}>
              <label>{signup.FIRST_NAME}</label>
              <input
                type="firstname"
                name="firstname"
                className="signup-page-email-input"
                placeholder={"Enter First Name"}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="input-div" style={{ marginTop: "20px" }}>
              <label>{signup.LAST_NAME}</label>
              <input
                type="lastname"
                name="lastname"
                className="signup-page-email-input"
                placeholder={"Enter Last Name"}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="input-div" style={{ marginTop: "20px" }}>
              <label>{signup.EMAIL_ADDRESS}</label>
              <input
                type="email"
                name="email"
                className="signup-page-email-input"
                placeholder={signup.EMAIL_ADDRESS_PLACEHOLDER}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            {error && <span className="error">{error}</span>}
            <button
              onClick={handleSubmit}
              className={
                disabled ? "large-green-button-disabled" : "large-green-button"
              }
              disabled={disabled}
              style={{ width: "100%", marginTop: "20px" }}
            >
              {signup.ADD_CUSTOMER}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateCustomerModal;
