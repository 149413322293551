import './MemberHome.scss'
import HeroSectionHome from '../../sections/HeroSectionHome'
import TopicGrid from '../../sections/HeroGridImages'
import PopularTopics from '../../sections/PopularTopics'
import IndividualSupport from '../../sections/IndividualSupport'
import { useEffect, useRef } from 'react'
import { getServices } from '../../utils/getService'
import { storageKeys } from '../../utils/constants'
import Loader from '../../component/common/Loader/Loader'
import useGlobalState from '../../context/useGlobalState'

const MemberPortalHome = () => {
  const { globalErrorHandler, setServiceType, setLoader, loader } = useGlobalState();
  const oId = localStorage.getItem(storageKeys.OrganizationID)
  const effectRef = useRef(false)
  
  useEffect(() => {
    if (effectRef.current === false) {
      getServices(oId, {
        globalErrorHandler,
        setServiceType,
        setLoader,
      });
    }
    return () => { effectRef.current = true }
  }, [oId])

  return (
    <>
      {
        loader ? <Loader /> : <div className='member-home-page'>
          <div className='container member-home-page'>
            <HeroSectionHome />
            <TopicGrid />
            <PopularTopics />
          </div>
          <IndividualSupport title='Individual Support' />
        </div>
      }
    </>
  )
}

export default MemberPortalHome