export const Signup = {
    title: "Your Well-Being is Our Priority",
    subtitle: "Create an account",
    button: "Sign Up",
    navlink: "Already have an account? Click here"
}

export const Login = {
    title: "Your Well-Being is Our Priority",
    subtitle: "Login Below",
    button: "Login",
    navlink: "Don’t have an account? Click here",
    emailLabel: "Email Address",
    emailPlaceHolder: "Work email address"

}

export const NavBarLinks = {
    EXPLORE_TOPIC: { name: "Explore Topics", path: "/explore-topics" },
    INDIVIDUAL_SUPPORT: { name: "Individual Support", path: "/individual-support" },
    FAQS: { name: "FAQs", path: "/faqs" },
    CONTACTUS: { name: "Contact Us", path: "/contact-us" }
}

export const Modules = {
    EMOTIONAL: "emotional",
    CAREGIVING: "caregiving",
    PHYSICAL: "physical",
    WEATHER: "weather",
    FINANCIAL: "financial well-being",
    EVERYDAYLIVE: "everyday living"
}

export const ChallengeTwoQuestions = ["What is the effect of telling myself this thought? Is this thought helpful? Does it serve me?",
    "Is there an alternative/more advantageous point of view?",
    "What could be the effect of changing my thinking?",
    "What is the evidence for my thought? What is the evidence against it? Which is more convincing?",
    "Is my thought logical? Is there another - perhaps more rational - way of looking at that? Is there another explanation?",
    "Am I really 100% certain about this, or is this just one out of many possibilities?",
    "So what if...?"
]

export const Questionnaires = [
    "Feeling nervous, anxious or on edge",
    "Not being able to stop or control worrying",
    "Worrying too much about different things",
    "Trouble relaxing",
    "Being so restless that its hard to sit still",
    "Becoming easily annoyed or irritable",
    "Feeling afraid, as if something awful might happen",
    "If you checked any problem, how difficult have they made it for you to do your work, take care of things at home, or get along with other people"
]

export type LessonType = "Video" | "Exercise" | "article";

export enum LessonTypes {
    Video = "Video",
    Exercise = "Exercise",
    Article = "Article"
}

export enum ExerciseTypes {
    singleJournalEntry = 'Single Journal Entry',
    multipleJournalEntry = 'Multiple Journal Entry',
    chartedJournalEntry = 'Charted Journal Entry',
    scoredQuestionnaire = 'Scored Questionnaire',
    quiz = 'Quiz'
}

export enum QuestionTypes {
    dateAndTime = "date_time",
    longText = "long_text",
    counter = "counter",
}

export enum ModuleStatus {
    notStarted = "not_started",
    inProgress = "in_progress",
    completed = "completed"
}

export const DaysPerWeek = [
    {
        time: "6AM to 8AM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "8AM to 10AM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "10AM to 12PM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "12PM to 2PM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "2PM to 4PM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "4PM to 6PM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "6PM to 8PM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "8PM to 10PM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
    {
        time: "10PM to 12AM",
        activities: {
            Monday: null,
            Tuesday: null,
            Wednesday: null,
            Thursday: null,
            Friday: null,
            Saturday: null,
            Sunday: null,
        },
    },
];

