import React from 'react';
import ExerciseInput from '../Input/ExerciseInput'
import { ApiAnswers } from '../../../interfaces/MemberPortal';

interface LongTextQuestionProps {
    item: ApiAnswers;
    editEntry: boolean;
    handleInputChange: (id: number, value: string | null | number | { date: null, time: null }, required: boolean, question: string) => void;
}

const LongTextQuestion: React.FC<LongTextQuestionProps> = ({ item, editEntry, handleInputChange }) => {
    return (
        <>
            {item.answers?.answer && !editEntry ? (
                <div className="ques-ans">
                    <span className="input-title">{item.question}</span>
                    <span>{item.answers?.answer}</span>
                </div>
            ) : (
                <>
                    {!item.is_required && item.answers && (!item.is_required && item.answers && editEntry) ? (
                        <div>
                            <span className="input-title">{item.question}</span>
                            <span className="answer">{item.answers?.answer}</span>
                        </div>
                    ) : (
                        <div className="margin-inputs">
                            <ExerciseInput
                                handleInputChange={handleInputChange}
                                name={item.question}
                                required={item.is_required}
                                id={item.id}
                                value={(item.answers?.answer || '') as string}
                                title={item.question}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default LongTextQuestion;
