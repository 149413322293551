import { common, storageKeys } from "./constants";

interface FormData {
  company: string;
  member_type: string;
  gender: string;
  marital_status: string;
  phone: string;
  leave_mail: string;
  street_address: string;
  apt_floor: string;
  city: string;
  state: string;
  zipcode: string;
}

interface LoginResponse {
  data: {
    data: {
      token: string;
      username: string;
      email: string;
      first_name: string;
      last_name: string;
      session_token: string;
      session_token_expiration_time: string;
      organization_id: string;
      organization_name: string;
      user_type: string;
      id: any;
    };
  };
}

interface TicketResponse {
  data: {
    data: {
      id: number;
      channelName: string;
      channelUrl: string;
    };
  };
}

class CredentialStorage {
  // Storing values of help-page form
  formValues(formData: FormData): void {
    const phoneWithoutDashes = formData.phone.replace(/-/g, "");
    localStorage.setItem(
      storageKeys.HelpForm,
      JSON.stringify({
        company: formData.company,
        client_type: formData.member_type,
        gender: formData.gender,
        marital_status: formData.marital_status,
        phone_number: phoneWithoutDashes,
        leave_voicemail: formData.leave_mail,
        street_address: formData.street_address,
        apt_floor: formData.apt_floor,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zipcode,
      })
    );
  }

  // Storing login credential after successful login/registration
  login(response: LoginResponse): void {
    const { data } = response.data;
    localStorage.setItem(storageKeys.Token, data.token);
    localStorage.setItem(storageKeys.UserID, data.username);
    localStorage.setItem(storageKeys.Email, data.email);
    localStorage.setItem(storageKeys.FirstName, data.first_name);
    localStorage.setItem(storageKeys.LastName, data.last_name);
    localStorage.setItem(storageKeys.SessionToken, data.session_token);
    localStorage.setItem(storageKeys.Company, data.organization_name);
    localStorage.setItem(storageKeys.OrganizationID, data.organization_id);
    localStorage.setItem(
      storageKeys.SessionExpiry,
      data.session_token_expiration_time
    );
    localStorage.setItem(storageKeys.UserType, data.user_type);
    localStorage.setItem(storageKeys.ID, data.id);
  }

  // Storing ticket info and channel info after create-ticket
  ticketInfo(response: TicketResponse): void {
    const { data } = response.data;
    const ticketId = data.id.toString();
    localStorage.setItem(storageKeys.TicketID, ticketId);
    localStorage.setItem(storageKeys.ChannelName, data.channelName);
    localStorage.setItem(storageKeys.ChannelURL, data.channelUrl);
  }

  eapxValues() {
    let flow = localStorage.getItem(storageKeys.Flow);
    let formData: any = localStorage.getItem(storageKeys.HelpForm);
    let parsedData: any = JSON.parse(formData);
    let email: any = localStorage.getItem(storageKeys.Email);
    let firstName: any = localStorage.getItem(storageKeys.FirstName);
    let lastName: any = localStorage.getItem(storageKeys.LastName);
    let dob: any = localStorage.getItem(storageKeys.BirthDate);
    let question_1: any =
      flow === common.SELF_SCHEDULING
        ? localStorage.getItem(storageKeys.SelfScheduleQuestion)
        : localStorage.getItem(storageKeys.TextCoachingQuestion);
    let age: any = localStorage.getItem(storageKeys.Age);

    return {
      company: parsedData?.company,
      email: email,
      first_name: firstName,
      last_name: lastName,
      dob: dob,
      age: age,
      gender: parsedData?.gender,
      phone_number: parsedData?.phone_number,
      street_address: parsedData?.street_address,
      state: parsedData?.state,
      zip_code: parsedData?.zip_code,
      marital_status: parsedData?.marital_status,
      client_type: parsedData?.client_type,
      question_1: question_1 ? question_1 : "No value",
      leave_voicemail: parsedData?.leave_voicemail,
      city: parsedData?.city,
    };
  }
}

const instance = new CredentialStorage();

export default instance;
