import React, { useEffect, useState } from "react";
import "./FixedHeaderUserLayout.css";
import RedirectCheck from "../../routes/RedirectCheck";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assests/icons/logo.png";
import menuIcon from "../assests/icons/menu.png";
import close from "../assests/icons/close.png";
import profile from "../assests/icons/profile.png";
import Toast from "../common/Toast/Toast";
import { headerText, storageKeys, signup, common, userType } from "../../utils/constants";
import { homeNavigation } from "../../utils/homeNavigation";
import { logout } from "../../utils/ApiClient";
import { clearLocalStorageExceptOID } from "../../utils/clearStorageData";
import Footer from "../common/Footer/Footer";
import MemberPortalHeader from "./header/MemberPortalHeader";
import useGlobalState from "../../context/useGlobalState";

interface Props {
  children: React.ReactNode;
  hideMyAccButton?: boolean;
}

const FixedHeaderUserLayout: React.FC<Props> = ({
  children,
  hideMyAccButton,
}) => {
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const { globalErrorHandler, menu, setMenu } = useGlobalState()
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const token = localStorage.getItem(storageKeys.Token);
  const hideMyAccountButton = hideMyAccButton ? true : false;
  const isNewVersion = true;

  const userTypeName = localStorage.getItem(storageKeys.UserType)

  const handleHomeNavigation = (): void => {
    if (token) {
      if (userTypeName === userType.CUSTOMER) {
        navigate("/account-info");
      } else if (userTypeName === userType.PRT_USER) {
        navigate("/prt-dashboard");
      } else {
        navigate("/prt-manager-dashboard")
      }
    } else {
      navigateToHomePage();
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      clearLocalStorageExceptOID(storageKeys.OrganizationID);
      navigate("/login");
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  const goToSettings = () => {
    navigate('/prt-settings')
  }

  const goToPRTManagerDashboard = () => {
    navigate('/prt-manager-dashboard')
  }

  const navigateToHomePage = () => {
    navigate("/")
  }

  useEffect(() => {
    if (currentPath.startsWith("/chat/") || currentPath === "/" || userTypeName === (userType.PRT_MANAGER) || userTypeName === (userType.PRT_USER) || isNewVersion) {
      setShowFooter(false);
    }
  }, []);

  return (
    <div className="fixed-header-component fixed-header-layout">
      <RedirectCheck />
      {
        isNewVersion ?
        <div className="header">
          <MemberPortalHeader
            handleHomeNavigation={navigateToHomePage}
            currentPath={currentPath} /></div>
          : <header className={`common-header ${(userTypeName === userType.PRT_MANAGER) || (userTypeName === userType.PRT_USER) ? "smaller-fixed-header" : "fixed-header"}`}>
            <div className="header">
              <div className="header-content-left">
                <img
                  src={logo}
                  alt="logo"
                  className="fixed-header-logo"
                  onClick={handleHomeNavigation}
                />
                {
                  (!userTypeName || userTypeName === userType.CUSTOMER) && <span className="header-title subtitle">{headerText.TITLE}</span>
                }
              </div>
            </div>
            <>
              {currentPath === "/" && (
                <img
                  src={menu ? close : menuIcon}
                  alt="menu-icon"
                  className="menu-icon"
                  onClick={() => setMenu(!menu)}
                />
              )}
              {hideMyAccountButton || !token ? (
                <>
                  {currentPath === "/" && (
                    <div onClick={handleNavigate} className="nav-link-div">
                      <span className="header-nav-link">{signup.LOGIN_TEXT1}</span>
                      <span className="header-nav-link">{signup.LOGIN_TEXT2}</span>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  {
                    userTypeName === userType.PRT_MANAGER ? (
                      <div className="prt-buttons">
                        {
                          currentPath === "/prt-manager-dashboard" ? <span className="prt-text" onClick={goToSettings}>{common.PRT_SETTINGS}</span> : <span className="prt-text" onClick={goToPRTManagerDashboard}>{common.PRT_DASHBOARD}</span>
                        }
                        <button className="prt-logout-btn" onClick={handleLogout}>{"Log Out"}</button>
                      </div>
                    ) : <>
                      {currentPath === "/account-info" || userTypeName === userType.PRT_MANAGER || userTypeName === userType.PRT_USER ? (
                        <div className="button-box">
                          <button className="logout-button" onClick={handleLogout}>
                            {"Logout"}
                          </button>
                        </div>
                      ) : (
                        <div className="user-div" onClick={handleHomeNavigation}>
                          <img src={profile} alt="user" className="avatar" />
                          <span className="user-div-text">{common.MY_ACCOUNT}</span>
                        </div>
                      )} </>
                  }
                </div>
              )}
            </>
          </header>
      }
      <Toast />
      <div className="content">{children}</div>
      {showFooter && !isNewVersion && <div className="footer-div"><Footer /></div>}
    </div>
  );
};

export default FixedHeaderUserLayout;
