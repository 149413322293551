import { createContext } from "react";
import { ApiLesson, Sections } from "../interfaces/MemberPortal";
interface GlobalState {
  globalError: string;
  serviceType: string[] | null;
  loader: boolean;
  menu: boolean;
  openNavMenu: boolean;
  isMenuExpanded: boolean;
  openProfileMenu: boolean;
  categories: [] | undefined;
  selectedNavMenu: string | null | undefined,
  cbtModuleSections: Sections[],
  lessonId: number | null | undefined,
  lessonDetails: ApiLesson,
  exerciseId: number | null| undefined,
  moduleId: number | null,
  fetchingLesson: boolean,
  setServiceType: (serviceType: string[] | null) => void;
  setGlobalError: (error: string) => void;
  globalErrorHandler: (error: any) => void;
  setLoader: (loading: boolean) => void;
  setMenu: (menu: boolean) => void;
  setCategories: (categories: []) => void;
  setOpenNavMenu: (menu: boolean) => void;
  setIsMenuExpanded: (menu: boolean) => void;
  setOpenProfileMenu: (menu: boolean) => void;
  setSelectedNavMenu: (menu: string | null) => void;
  setExerciseId: (id: number | null) => void;
  setModuleID: (id: number | null) => void;
  setFetchingLesson: (fetching: boolean) => void;
  setLessonId: (id: number | null) => void;
  setcbtModuleSections: (sections: Sections[]) => void;
  setLessonDetails: (lesson: ApiLesson) => void;
}

const GlobalContext = createContext<GlobalState | undefined>(undefined);

export type { GlobalState };
export default GlobalContext;
