import React, { useState, useEffect, useRef } from 'react'
import EAPCaseSideBar from '../../common/SideTabs/eap-cases-sidebar/EAPCaseSideBar'
import { getEAPCases } from '../../../utils/ApiClient'
import Loader from '../../common/Loader/Loader'
import AdditionalProviders from './AdditionalProviders'
import './PRTDashboardHome.scss'
import CaseDetails from './CaseDetails'
import { PRTUserCasesType, ProviderStatus } from '../../../utils/constants'
import NoteSection from './NoteSection'
import { PRTCaseDetails } from '../../../interfaces/PRTCaseDetails'
import useGlobalState from '../../../context/useGlobalState'

export type Provider = {
    eapx_case: string;
    eapx_file_number: number | undefined;
    id: number;
    provider_detail: string;
    status: string;
    full_address: string;
    phone: string;
    email: string;
    notes: string;
    assigned_date: string | null;
    provider_status: string | null;
    provider_status_display: string | null
};

const PRTDashboardHome: React.FC = () => {
    const [activeCase, setActiveCase] = useState<any>("")
    const [cases, setCases] = useState<any[]>([])
    const [caseStatus, setCaseStatus] = useState<string>("")
    const [activeCaseInfo, setActiveCaseInfo] = useState<PRTCaseDetails>()
    const [casesTypeName, setCasesTypeName] = useState<string>("My Cases")
    const [loading, setLoading] = useState<boolean>(true)
    const [caseLoading, setCaseLoading] = useState<boolean>(false)
    const [additionalProviders, setAdditionalProviders] = useState<{ [caseId: string]: Provider[] }>({});
    const [noData, setNoData] = useState(false)
    const [specialist, setSpecialist] = useState("")
    const [caseClaimed, setCaseClaimed] = useState<boolean>(false)
    const [fileNo, setFileNo] = useState<number>(0)
    const { globalErrorHandler } = useGlobalState()
    const [notes, setNotes] = useState<string>("")
    const [emptyNotes, setEmptyNotes] = useState<boolean>(false)
    const showButton = (casesTypeName !== PRTUserCasesType.CLOSED.label)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [caseReopen, setCaseReopen] = useState<boolean>(false)
    const effectRef = useRef(false);

    const addProvider = () => {
        if (activeCase) {
            const newProvider: Provider = {
                id: 0,
                provider_detail: '',
                status: '',
                notes: '',
                full_address: '',
                email: '',
                phone: '',
                eapx_case: activeCase,
                eapx_file_number: activeCaseInfo?.file_number,
                assigned_date: null,
                provider_status: ProviderStatus.REQUEST_SENT.value,
                provider_status_display: ProviderStatus.REQUEST_SENT.label
            };
            setAdditionalProviders((prevState: any) => ({
                ...prevState,
                [activeCase]: [...(prevState[activeCase] || []), newProvider]
            }));
        }
    };

    const caseData = (data: any) => {
        if (data !== null || data !== undefined) {
            setSpecialist(data?.assigned_specialist)
            setCaseStatus(data?.eapx_file_status_display)
            setNotes(data?.notes)
        }
    }

    const getEapCaseData = async (caseType : string): Promise<void> => {
        try {
            const response = await getEAPCases(caseType)
            if (!response.data.data.cases.length && response.data.data.first_case_detail === null) {
                setNoData(true)
                setCases([])
            }
            else {
                setCases(response.data.data.cases);
                setActiveCase(response.data.data.first_case_detail.id)
                setActiveCaseInfo(response.data.data.first_case_detail)
                caseData(response.data.data.first_case_detail)
                setNoData(false)
            }
            setLoading(false)
            setCaseLoading(false)
            setCaseReopen(false)
        } catch (error) {
            globalErrorHandler(error)
            setLoading(false)
            setCaseLoading(false)
        }
    }

    useEffect(() => {
        if (effectRef.current === false) {
            getEapCaseData( PRTUserCasesType.MY_CASES.value)
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (caseClaimed || caseReopen) {
            getEapCaseData( PRTUserCasesType.MY_CASES.value)
        }
    }, [caseClaimed, caseReopen])

    useEffect(() => {
        if (activeCase && activeCaseInfo) {
            setAdditionalProviders((prevState: any) => ({
                ...prevState,
                [activeCase]: activeCaseInfo.provider_outreach.map((value: any) => ({ ...value, status: "Save" }))
            }));
        }
    }, [activeCaseInfo]);

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setNotes(notes)
                setSuccessMessage("")
            }, 5000)
        }
    }, [successMessage])

    useEffect(() => {
        if (emptyNotes) {
            setTimeout(() => {
                setEmptyNotes(false)
            }, 5000)
        }
    }, [emptyNotes])

    useEffect(() => {
        if (cases?.length) {
            caseData(activeCaseInfo)
        }
    }, [activeCase, activeCaseInfo, cases, casesTypeName])

    return (
        <div className='eap-cases-page'>
            {
                loading ? <div className='loader-component-section'> <Loader /></div> :
                    <div className='case-info-section'>
                        <EAPCaseSideBar
                            onCaseTypeTabChange={getEapCaseData}
                            setLoading={setLoading}
                            tabs={PRTUserCasesType}
                            cases={cases}
                            setActiveCaseInfo={setActiveCaseInfo}
                            casesTypeName={casesTypeName}
                            setCasesTypeName={setCasesTypeName}
                            activeCase={activeCase}
                            setActiveCase={setActiveCase}
                            setCaseLoading={setCaseLoading}
                            setCaseClaimed={setCaseClaimed}
                            caseStatus={caseStatus}
                            caseLoading={caseLoading}
                            setFileNo={setFileNo}
                            setNoData={setNoData}
                            setCaseReopen={setCaseReopen} />
                        {
                            caseLoading ? <div className='case-content'>
                                <Loader />
                            </div> : <React.Fragment>
                                {
                                    noData ? <span className='no-data-section'>{"No cases claimed"}</span> :
                                        <div className='case-content'>
                                            <CaseDetails
                                                activeCaseInfo={activeCaseInfo}
                                                caseStatus={caseStatus}
                                                caseLoading={caseLoading}
                                                setCaseLoading={setCaseLoading}
                                                setCaseStatus={setCaseStatus}
                                                activeCase={activeCase}
                                                additionalProviders={additionalProviders}
                                                setSpecialist={setSpecialist}
                                                specialist={specialist}
                                                casesTypeName={casesTypeName}
                                                setCasesTypeName={setCasesTypeName}
                                                setCaseReopen={setCaseReopen} />
                                            <div className='case-sub-section'>
                                                {additionalProviders[activeCase]?.map((provider: any, index) => (
                                                    <AdditionalProviders
                                                        onCaseTypeTabChange={getEapCaseData}
                                                        key={index + 2}
                                                        providerNo={index}
                                                        provider={provider}
                                                        activeCase={activeCase}
                                                        setAdditionalProviders={setAdditionalProviders}
                                                        additionalProviders={additionalProviders}
                                                        caseStatus={caseStatus}
                                                        setCasesTypeName={setCasesTypeName}
                                                        setNoData={setNoData}
                                                        setLoading={setLoading}
                                                        setCases={setCases}
                                                        setActiveCase={setActiveCase}
                                                        setActiveCaseInfo={setActiveCaseInfo}
                                                        casesTypeName={casesTypeName} />
                                                ))}
                                                {
                                                    showButton && <button className='add-button' onClick={addProvider}>{" + Add additional Provider"}</button>
                                                }
                                            </div>
                                            <NoteSection
                                                notes={notes}
                                                setNotes={setNotes}
                                                casesTypeName={casesTypeName}
                                                fileNo={activeCaseInfo?.file_number}
                                                setSuccessMessage={setSuccessMessage} />
                                            {successMessage && <span className='success-message'>{successMessage}</span>}
                                        </div>
                                }
                            </React.Fragment>
                        }
                    </div>
            }
        </div>
    )
}

export default PRTDashboardHome 