import React, { useEffect, useState } from 'react'

interface MessageProps {
    message: string
}

const SuccessMessage: React.FC<MessageProps> = ({ message }) => {
    const [disappearMessage, setdisappearMessage] = useState(true)

    useEffect(() => {
        if (message) {
            setdisappearMessage(false)
            setTimeout(() => {
                setdisappearMessage(true)
            }, 3000)
        }
    }, [message])

    return (
        <>
            {
                disappearMessage ? null : <div className='success'>{message}</div>
            }
        </>
    )
}

export default SuccessMessage