import { storageKeys } from "./constants";

export const homeNavigation = () => {
  const oid: any = localStorage.getItem(storageKeys.OrganizationID);
  const queryString = new URLSearchParams({
    oid: oid,
  }).toString();
  const newUrl = oid ? `/?${queryString}` : "/";
  window.location.href = newUrl;
};
