import { WorkLifeCategory, storageKeys } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import {
  Category,
  workLifeData,
  Request,
} from "../../../../utils/workLifeData";
import "../../../../styles/gridCard.scss";
import "../../../../styles/globalStyles.scss";

const WorkLifeCategories: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigation = (item: Request | Category) => {
    localStorage.setItem(storageKeys.Category, item.name);
    navigate(`/category/${item.id}`);
  };

  return (
    <div className="worklife-category-page page-container">
      <span className="title">{WorkLifeCategory.CATEGORY_TITLE}</span>
      <div className="grid-container">
        {workLifeData?.map((item: Category, index: number) => {
          return (
            <div
              className="card"
              key={index}
              onClick={() => handleNavigation(item)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkLifeCategories;
