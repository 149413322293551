import React, { useState, useEffect } from 'react'
import './JournalEntry.scss'
import EditIcon from '../../assests/icons/edit-icon.png'
import BinIcon from '../../assests/icons/green-bin.png'
import CancelIcon from '../../assests/icons/cancel.png'
import { ApiAnswers } from '../../../interfaces/MemberPortal'
import ExerciseInput from '../Input/ExerciseInput'
import IncrementDecrementButton from '../Counter/IncrementDecrementButton'
import DateAndTimeInput from '../Input/DateAndTimeInput'
import SmallGreenButton from '../Button/SmallGreenButton'
import { QuestionTypes } from '../../../utils/member-portal/constant'
import { deleteJournalAnswer, editJournalAnswer, submitExerciseAnswers } from '../../../utils/ApiClient'
import { formatTime, validateAnswers } from '../../../utils/helperFunctions'
import { FetchApiData } from '../../../services/useFetchData'
import ConfirmDialogBox from '../DialogBox/ConfirmDialogBox'
import SuccessMessage from '../SuccessMessage/SuccessMessage'
import useGlobalState from '../../../context/useGlobalState'
import LoadingButton from '../Button/LoadingButton'

interface JournalEntryProps {
    entryId?: number;
    lessonId: number;
    questions: ApiAnswers[];
    setJournalEntries: React.Dispatch<React.SetStateAction<ApiAnswers[][]>>;
    entryNo: number;
    isNewEntry: boolean;
    entryIndex?: number
}

export type DateTimeQuestion = { id: number; value: Date | null };

export type AnswerType = {
    question_id: number;
    answer: string | number | null | { date: Date | null, time: null | Date | string };
    is_required: boolean;
    question: string
};

export function getAnswerDefaultValue(type: string) {
    switch (type) {
        case QuestionTypes.dateAndTime:
            return { date: new Date(), time: new Date() };
        case QuestionTypes.longText:
            return '';
        case QuestionTypes.counter:
            return 0;
        default:
            return ''
    }
}

const JournalEntry: React.FC<JournalEntryProps> = ({ questions, lessonId, setJournalEntries, entryNo, entryId, isNewEntry }) => {
    const [question, setQuestion] = useState<ApiAnswers[]>(questions);
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>("")
    const [editingId, setEdigtingId] = useState<number | null>(null)
    const [openDialogBox, setOpenDialogBox] = useState(false)
    const { globalErrorHandler } = useGlobalState()
    const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false)
    const { fetchLesson } = FetchApiData()
    const showSaveButton = (entryId === editingId || isNewEntry)
    const [loading, setLoading] = useState<boolean>(false)
    const [changeName, setChangeName] = useState<boolean>(false)

    useEffect(() => {
        setQuestion(questions);
        setAnswers(
            questions.map((value) => ({
                question: value.question,
                question_id: value.id,
                answer: value.answers?.answer
                    ? (
                        value.question_type === QuestionTypes.dateAndTime
                            ? {
                                date: value.answers.answer.date ? new Date(value.answers.answer.date) : new Date(),
                                time: value.answers.answer.time ? value.answers.answer.time : null,
                            }
                            : value.answers.answer
                    )
                    : getAnswerDefaultValue(value.question_type),
                is_required: value.is_required,
            }))
        );

    }, [questions]);

    const editJournal = async (): Promise<void> => {
        setLoading(true)
        try {
            const cleanedData = answers.map(({ question, is_required, ...rest }) => rest);
            const response = await editJournalAnswer(lessonId, entryId ?? 0, cleanedData)
            setSuccessMessage(response.data.message);
            setChangeName(true)
            setTimeout(() => {
                setLoading(false)
                setSuccessMessage(response.data.message)
                fetchLesson(lessonId)
            }, 3000)
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    const closeDialogBox = () => {
        setIsButtonDisable(false)
        setOpenDialogBox(false)
    }

    const handleSaveClick = async (): Promise<void> => {
        const cleanedData = answers.map(({ question, is_required, ...rest }) => rest);
        if (isNewEntry) {
            setErrorMessage(null)
            const hasError = validateAnswers(answers)
            if (!hasError) {
                setError(false)
                setLoading(true)
                try {
                    const response = await submitExerciseAnswers(lessonId, cleanedData)
                    setChangeName(true)
                    setTimeout(() => {
                        setLoading(false)
                        setSuccessMessage(response.data.message)
                        fetchLesson(lessonId)
                    }, 3000)
                } catch (error) {
                    globalErrorHandler(error)
                }
            }
            else {
                setErrorMessage(hasError)
                setError(true)
            }
        } else {
            editJournal()
        }
    };

    const handleInputChange = (
        id: number,
        value: string | number | null | { date: Date | null, time: Date | null | string },
        isRequired: boolean,
        question: string
    ) => {
        const formattedValue =
            value instanceof Date
                ? { date: value, time: null }
                : value;
        setError(false);
        const updatedAnswers = answers.map((answer: AnswerType) =>
            answer.question_id === id
                ? { ...answer, answer: formattedValue, is_required: isRequired, question: question }
                : answer
        );
        setAnswers(updatedAnswers);
    };


    const editJournalEntry = () => {
        setEdigtingId(entryId ?? null)
    }

    const removejouralEntry = async (): Promise<void> => {
        try {
            const response = await deleteJournalAnswer(entryId ?? 0)
            setSuccessMessage(response.data.message);
            setTimeout(() => {
                fetchLesson(lessonId)
            }, 3000)
            closeDialogBox()
        } catch (error) {
            closeDialogBox()
            globalErrorHandler(error)
        }
    }

    const deleteEntry = async (): Promise<void> => {
        if (entryId) {
            setOpenDialogBox(true)
        } else {
            setJournalEntries((prevEntries: any) =>
                prevEntries.filter((_: any, index: number) => index !== entryNo)
            );
        }
    }

    return (
        <div className='journal-entry-component'>
            {question.map((ques: ApiAnswers, index) => {
                return (
                    <div key={index}>
                        <div className='content-section'>
                            {
                                ques.question_type === QuestionTypes.dateAndTime && (
                                    <>  {
                                        ques?.answers?.answer && entryId !== editingId ? <div className='journal-header'>
                                            <div className='title-button-bar'>
                                                <span className='input-title'>Journal Entry {entryNo + 1}</span>
                                                <div className='icons-section'>
                                                    <img src={EditIcon} className='green-bin-icon' onClick={editJournalEntry} />
                                                    <img src={BinIcon} alt='bin-icon' className='green-bin-icon' onClick={deleteEntry} />
                                                </div>
                                            </div>
                                            <span className='date-n-time'>{ques.answers.answer.date} {formatTime(ques.answers.answer.time)}</span>
                                        </div> :
                                            <div className='journal-header'>
                                                <div className='title-button-bar'>
                                                    <span className='input-title'>Journal Entry</span>
                                                    <div className='icons-section'>
                                                        {
                                                            editingId && <img src={CancelIcon} className='green-bin-icon' onClick={() => setEdigtingId(null)} />
                                                        }
                                                        <img src={BinIcon} alt='bin-icon' className='green-bin-icon' onClick={deleteEntry} />
                                                    </div>
                                                </div>
                                                <DateAndTimeInput
                                                    hideTitle={true}
                                                    required={ques.is_required}
                                                    title={ques.question}
                                                    hideButton={true}
                                                    id={ques.id}
                                                    value={{
                                                        date: ques?.answers?.answer ? new Date(ques.answers.answer.date) : new Date(),
                                                        time: ques?.answers?.answer ? ques.answers.answer.time : new Date()
                                                    }}
                                                    handleInputChange={handleInputChange}
                                                />
                                            </div>
                                    }
                                    </>
                                )
                            }
                            {
                                ques.question_type === QuestionTypes.longText && (
                                    <>
                                        {
                                            ques?.answers?.answer && entryId !== editingId ? <div>
                                                <span className='journal-question'>{ques?.question}</span>
                                                <span>{ques?.answers?.answer}</span></div> :
                                                <>
                                                    {
                                                        !ques.is_required && ques.answers && (!ques.is_required && ques.answers && entryId !== editingId) ?
                                                            <div>
                                                                <span className='journal-question'>{ques?.question}</span>
                                                                <span>{ques?.answers?.answer}</span>
                                                            </div> :
                                                            <ExerciseInput
                                                                handleInputChange={handleInputChange}
                                                                name={ques.question}
                                                                required={ques.is_required}
                                                                id={ques.id}
                                                                value={(ques?.answers?.answer || '') as string}
                                                                title={ques.question} />
                                                    }
                                                </>
                                        }
                                    </>
                                )
                            }
                            {
                                ques.question_type === QuestionTypes.counter && (
                                    <div className='journal-que-ans'>
                                        {
                                            (ques?.answers?.answer || ques?.answers?.answer === 0) && entryId !== editingId ? (
                                                <div className='count-ques-ans'>
                                                    <span className='journal-question'>{ques?.question} : </span>
                                                    <span className='journal-question'>{ques?.answers?.answer || 0}</span>
                                                </div>
                                            ) : (
                                                <IncrementDecrementButton
                                                    handleInputChange={handleInputChange}
                                                    title={ques.question}
                                                    id={ques.id}
                                                    required={ques.is_required}
                                                    answer={answers[index]}
                                                />
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            })}
            {
                error && errorMessage && <span className='error'>{errorMessage}</span>
            }
            {
                successMessage && <SuccessMessage message={successMessage} />
            }
            {
                loading && <LoadingButton changeName={changeName} />
            }
            {(showSaveButton && !loading) && <SmallGreenButton name={"Save"} onClick={handleSaveClick} />}
            {
                openDialogBox && <ConfirmDialogBox
                    title='Are you sure want to delete journal entry?'
                    content=""
                    confirmLabel={"Confirm"}
                    onConfirm={removejouralEntry}
                    handleClose={closeDialogBox}
                    openDialogBox={openDialogBox}
                    buttonDisabled={isButtonDisable} />
            }
        </div >
    )
}

export default React.memo(JournalEntry)